<template>
  <RenderlessComponent>
    <slot />
  </RenderlessComponent>
</template>

<script>
  import RenderlessComponent from 'src/components/RenderlessComponent/RenderlessComponent.vue';

  export default {
    name: 'VisitorProvider',
    components: { RenderlessComponent },
    props: {
      visitor: {
        type: Object,
        required: true,
      }
    },
    provide() {
      return { visitor: this.visitor }
    },
  }
</script>