<template>
  <BasicLink
    v-if="item.href"
    :href="item.href"
    :title="item.title"
    :appearance="appearance"
  />
  <span v-else v-text="item.title" />
</template>

<script>
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  
  export default {
    name: 'LinkOrText',
    components: { BasicLink },
    props: {
      item: {
        type: Object,
        required: true,
      },
      appearance: {
        type: String,
        default: 'dark'
      }
    }
  }
</script>