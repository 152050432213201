<template>
  <div class="expert-profile">
    <ExpertStats :score="expert.score" />
    <ExpertLinksSection
      title="Работаю с квалификациями"
      :list="expert.qualifications"
    />
    <ExpertLinksSection title="Специализация" :list="expert.specializations" />
    <ExpertLinksSection
      v-if="expert.skills && expert.skills.length"
      title="Помогаю развивать навыки"
      :list="expert.skills"
    />
    <ContentSection title="Работаю с запросами" appearance="expert-profile">
      <ExpertRequestsList :requests="expert.requests" />
    </ContentSection>
    <ContentSection
      v-if="expert.description"
      title="Экспертность"
      appearance="expert-profile"
    >
      <FadedContent
        :show="false"
        expand-title="Развернуть"
        collapse-title="Свернуть"
        button-appearance="pseudo-link-bold"
        appearance="inline"
        minimal
      >
        <StyleUgc :ugc="expert.description" />
      </FadedContent>
    </ContentSection>
    <div class="expert-profile__rate-section">
      <div class="expert-rate">
        <div class="expert-rate__title">Стоимость</div>
        <ExpertCost class="expert-rate__cost" :rate="expert.rate" />
        <ConsultationRequestButton
          v-if="!user.isOwner"
          :expert-login="user.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getRussianPluralDeclension } from 'src/utils/translation.js';

import ConsultationRequestButton from 'src/components/ConsultationRequestButton/ConsultationRequestButton.vue';
import ExpertCost from 'src/components/experts/ExpertCost/ExpertCost.vue';
import ExpertLinksSection from 'src/components/experts/ExpertLinksSection/ExpertLinksSection.vue';
import ExpertRequestsList from 'src/components/experts/ExpertRequestsList/ExpertRequestsList.vue';
import ExpertStats from 'src/components/experts/ExpertStats/ExpertStats.vue';
import FadedContent from 'src/components/FadedContent/FadedContent.vue';
import ContentSection from 'src/components/sections/ContentSection/ContentSection.vue';
import StyleUgc from 'src/components/StyleUgc/StyleUgc.vue';

export default {
  name: 'ExpertProfile',
  components: {
    ConsultationRequestButton,
    ExpertCost,
    ExpertStats,
    ExpertLinksSection,
    ExpertRequestsList,
    FadedContent,
    StyleUgc,
    ContentSection,
  },
  props: {
    expert: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    hasActiveConnection: Boolean,
  },
  computed: {
    consultsText() {
      return getRussianPluralDeclension(
        'консультация',
        'консультации',
        'консультаций',
      )(this.expert.total_connections);
    },
    scoresText() {
      return getRussianPluralDeclension(
        'оценка',
        'оценки',
        'оценок',
      )(this.expert.scores_count);
    },
  },
};
</script>
