<template>
  <span class="highlighted-item">
    <slot />
  </span>
</template>

<script>
  export default {
    name: 'HighlightedItem'
  }
</script>