import autosize from 'autosize';

export const autosizeDirective = {
  bind(el) {
    autosize(el);
  },
  inserted(el) {
    autosize.update(el);
  },
  update(el) {
    autosize.update(el);
  },
  unbind(el) {
    autosize.destroy(el);
  }
}