<template>
  <div
    class="suggestion-items"
    :class="bem('suggestion-items', { appearance, embedded })"
    :style="style"
    ref="root"
  >
    <div
      v-if="$slots.beforeItems"
      class="suggestion-items__item"
    >
      <slot name="beforeItems" />
    </div>
    <div
      v-for="suggestion in items"
      :key="getKey(suggestion)"
      :data-suggestion-value="suggestion.value"
      class="suggestion-items__item"
    >
      <slot :item="suggestion" :active="isActive(suggestion)">
        <SuggestionItem
          :item="suggestion"
          :active="isActive(suggestion)"
          :multiple="multiple"
          @click="$emit('click', suggestion)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import SuggestionItem from 'src/components/suggestions/SuggestionItem/SuggestionItem.vue';

  export default {
    name: 'SuggestionItems',
    components: { SuggestionItem },
    inject: ['mediaQueries'],
    props: {
      items: {
        type: Array,
        required: true,
      },
      appearance: String,
      activeSuggestion: Object,
      scrollToActiveSuggestion: Boolean,
      embedded: Boolean,
      multiple: Boolean,
      maxHeight: Number,
    },
    emits: ['click'],
    watch: {
      activeSuggestion(suggestion) {
        if (!this.scrollToActiveSuggestion) return;
        if (!suggestion) return;
        const el = this.$refs.root.querySelector(`[data-suggestion-value="${suggestion.value}"]`);
        if (!el) return;
        el.scrollIntoView({
          block: 'nearest',
          behavior: 'auto'
        });
      },
    },
    methods: {
      bem,
      isActive(suggestion) {
        return this.activeSuggestion && this.activeSuggestion.value === suggestion.value
      },
      getKey(item) {
        return item.value && typeof item.value === 'object' ? JSON.stringify(item.value) : item.value;
      },
    },
    computed: {
      style() {
        return this.mediaQueries.mobile ? { 'max-height': `${this.maxHeight}px` } : null
      }
    }
  }
</script>
