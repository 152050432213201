<template>
  <div class="expert-label">
    <SvgIcon icon="expert" size="24" />
    <span>Эксперт</span>
  </div>
</template>

<script>
  import SvgIcon from '../../icons/SvgIcon/SvgIcon.vue';

  export default {
    name: 'ExpertLabel',
    components: { SvgIcon }
  };
</script>
