<template>
  <TransitionFade body-scroll-lock>
    <ModalWindow v-if="isModalVisible" @close="close" :has-header="false">
      <div class="skill-result-modal">
        <div class="skill-result-modal__header">
          {{ modalInfo.skillName }}
        </div>
        <div class="skill-result-modal__level">
          {{ `Уровень «${modalInfo.level}»` }}
        </div>
        <div class="skill-result-modal__date">
          {{ `Тест пройден: ${formatLastResult}` }}
        </div>
        <a
          class="skill-result-modal__company"
          :href="$options.companyInfo.link"
          target="_blank"
        >
          <img
            class="skill-result-modal__company-icon"
            src="~images/YP_avatar.png"
            width="40"
            height="40"
            alt=""
          />
          <div class="skill-result-modal__company-info">
            <div class="skill-result-modal__company-name">
              {{ $options.companyInfo.name }}
            </div>
            <div class="skill-result-modal__company-label">Автор теста</div>
          </div>
          <SvgIcon
            class="skill-result-modal__company-arrow"
            icon="arrow"
            color="muted-blue"
            size="24"
          />
        </a>
        <BasicButton appearance="secondary" size="l" @click="close">
          Закрыть
        </BasicButton>
      </div>
    </ModalWindow>
  </TransitionFade>
</template>

<script>
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

export default {
  name: 'SkillResultModal',
  components: {
    TransitionFade,
    BasicButton,
    ModalWindow,
    SvgIcon,
  },
  companyInfo: {
    name: 'Яндекс Практикум',
    link: 'https://career.habr.com/education_centers/35-yandeks-praktikum',
  },
  props: {
    modalInfo: {
      type: Object,
    },
    isModalVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
  computed: {
    formatLastResult() {
      if (!this.modalInfo.lastResult) return '';
      const date = new Date(this.modalInfo.lastResult);
      return format(date, 'd MMM yyyy', { locale: ruLocale });
    },
  },
};
</script>
