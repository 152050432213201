<template>
  <ResourceAction
    ref="resourceAction"
    :action="action"
    :params="params"
    @success="onSuccess"
  >
    <DropdownTarget ref="dropdownTarget" v-slot="{ visible }">
      <BasicButton
        :appearance="buttonAppearance"
        :size="size"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @click="onClick"
        :title="buttonTitle"
        class="friendship-button__button"
      >
        <template v-if="iconOnlyAppearance">
          <SvgIcon icon="person" :color="iconColor" :size="24" />
          <span
            class="friendship-button__additional-icon"
            :class="
              bem('friendship-button__additional-icon', { color: iconColor })
            "
          >
            <svg
              v-if="modelValue === 'none'"
              viewBox="0 0 8 8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 0h2v3h3v2H5v3H3V5H0V3h3V0z" fill="currentColor" />
            </svg>
            <svg
              v-else
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 3L4 6L9 1" stroke="currentColor" stroke-width="2" />
            </svg>
          </span>
        </template>

        <template v-else>
          {{ buttonText }}
        </template>
      </BasicButton>

      <TransitionFade>
        <BasicConfirm
          v-if="confirmVisible"
          red-confirm-button
          confirm-label="Удалить"
          @close="confirmVisible = false"
          @confirm="$refs.resourceAction.dispatch()"
        >
          Удалить из друзей?
        </BasicConfirm>
      </TransitionFade>
      <TransitionFade>
        <BasicDropdown v-if="visible">
          <BasicSection>
            <SectionGroup>
              <BasicText>
                Чтобы добавить друга необходимо заполнить капчу:
              </BasicText>
              <RecaptchaField ref="recaptcha" @verify="onCaptchaVerify" />
            </SectionGroup>
          </BasicSection>
        </BasicDropdown>
      </TransitionFade>
    </DropdownTarget>
  </ResourceAction>
</template>

<script>
import { notify } from 'src/notify.js';
import { PersonalResource } from 'src/services/personal.js';
import { bem } from 'src/utils/bem.js';
import { modelValueConfig } from 'src/utils/vue.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicConfirm from 'src/components/BasicConfirm/BasicConfirm.vue';
import BasicDropdown from 'src/components/BasicDropdown/BasicDropdown.vue';
import DropdownTarget from 'src/components/DropdownTarget/DropdownTarget.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import RecaptchaField from 'src/components/RecaptchaField/RecaptchaField.vue';
import ResourceAction from 'src/components/ResourceAction/ResourceAction.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
import SectionGroup from 'src/components/sections/SectionGroup/SectionGroup.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

export default {
  name: 'FriendshipButton',
  components: {
    ResourceAction,
    SectionGroup,
    TransitionFade,
    BasicText,
    RecaptchaField,
    BasicSection,
    BasicDropdown,
    DropdownTarget,
    BasicButton,
    BasicConfirm,
    SvgIcon,
  },
  model: modelValueConfig(),
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'm',
    },
    addButtonText: {
      type: String,
      default: 'В друзья',
    },
    appearance: String,
  },

  emits: ['update:modelValue'],

  data() {
    return {
      confirmVisible: false,
      hovered: false,
    };
  },
  methods: {
    bem,
    onSuccess({
      data: { status, error, message },
      params: { 'g-recaptcha-response': captcha },
    }) {
      this.confirmVisible = false;

      if (error) {
        if (error.type === 'captcha') {
          this.$refs.dropdownTarget.show();
          if (captcha) {
            this.$refs.recaptcha.resetCaptcha();
          }
        }
        if (error.message) {
          notify({ type: 'error', message: error.message });
        }
      } else {
        this.$emit('update:modelValue', status);
        this.$refs.dropdownTarget.hide();
      }
      if (message) {
        notify({ type: 'info', message });
      }
    },
    onClick() {
      if (this.modelValue === 'accepted') {
        this.confirmVisible = true;
        return;
      }

      this.$refs.resourceAction.dispatch();
    },
    onCaptchaVerify(captcha) {
      this.$refs.resourceAction.dispatch({ captcha });
    },
  },
  computed: {
    iconOnlyAppearance() {
      return this.appearance === 'icon-only';
    },
    action() {
      return this.modelValue === 'incoming'
        ? PersonalResource.acceptFriendRequest
        : PersonalResource.toggleFriendship;
    },
    buttonTitle() {
      const { modelValue } = this;
      if (!this.iconOnlyAppearance) return null;
      if (modelValue === 'pending') return 'Отменить запрос';
      if (modelValue === 'accepted') return 'Удалить из друзей';

      return 'Добавить в друзья';
    },
    iconColor() {
      if (this.modelValue === 'accepted') return 'ui-green';

      return 'muted-blue';
    },
    buttonText() {
      const { modelValue, hovered } = this;
      if (modelValue === 'pending') {
        return hovered ? 'Отменить' : 'Запрошено';
      }
      if (modelValue === 'accepted') {
        return hovered ? 'Убрать' : 'Ваш друг';
      }
      return this.addButtonText;
    },
    buttonAppearance() {
      if (this.appearance) return this.appearance;
      return this.modelValue === 'none'
        ? 'friendship-primary'
        : 'friendship-secondary';
    },
    params() {
      const { userId, modelValue: status } = this;
      return { userId, status };
    },
  },
};
</script>
