<template>
  <div class="resume-educations">
    <SeparatedList
      :items="educations"
      :get-key="(education) => education.id"
      gap="medium"
      v-slot="{ item }"
    >
      <ResumeEducationItem :education="item" />
    </SeparatedList>
  </div>
</template>

<script>
  import ResumeEducationItem from "src/components/resume/ResumeEducationItem/ResumeEducationItem.vue";
  import SeparatedList from "src/components/SeparatedList/SeparatedList.vue";

  export default {
    name: 'ResumeEducations',
    components: { ResumeEducationItem, SeparatedList },
    props: {
      educations: {
        type: Array,
        required: true,
      }
    }
  }
</script>