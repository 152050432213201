import { apiUrl, cancellableRequest } from "src/services/api.js";

export const ArsenalCandidatesResource = {
  addUser({ companyId, login }) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrl}/companies/${companyId}/crm/candidates`,
      params: {
        login,
      }
    });
  },
  updateStatus({ companyId, id, status }) {
    return cancellableRequest({
      method: 'patch',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}`,
      params: {
        status,
      }
    });
  },
  getList({ companyId, type, page }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/companies/${companyId}/crm/candidates`,
      params: {
        type,
        page,
      }
    });
  },
  getEvents({ companyId, id, order, page }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/events`,
      params: {
        order,
        page,
      }
    });
  },
  getTags({ companyId, id }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/tags`
    });
  },
  addTag({ companyId, id, tag, list }) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/tags`,
      params: {
        tag,
        list
      }
    });
  },
  deleteTag({ companyId, id, tagId }) {
    return cancellableRequest({
      method: 'delete',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/tags/${tagId}`
    });
  },
  addNote({ companyId, id, note, events }) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/notes`,
      params: {
        note,
        events
      }
    });
  },
  updateNote({ companyId, id, noteId, message }) {
    return cancellableRequest({
      method: 'patch',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/notes/${noteId}`,
      params: {
        message,
      }
    });
  },
  restoreNote({ companyId, id, noteId }) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/notes/${noteId}/restore`,
    });
  },
  deleteNote({ companyId, id, noteId }) {
    return cancellableRequest({
      method: 'delete',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/notes/${noteId}`
    });
  },
  getResume({ companyId, id }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/companies/${companyId}/crm/candidates/${id}/resume`
    });
  },
  search({ companyId, query, page }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/companies/${companyId}/crm/candidates`,
      params: {
        query,
        page,
      }
    });
  },
}

export const candidateStatuses = [
  {
    title: 'Новый',
    value: 'new',
  },
  {
    title: 'Начало общения',
    value: 'in_progress',
  },
  {
    title: 'Проходит собеседование',
    value: 'interview',
  },
  {
    title: 'Сделали оффер',
    value: 'offered',
  },
  {
    title: 'Испытательный срок',
    value: 'probation',
  },
  {
    title: 'Взят на работу',
    value: 'hired',
  },
  {
    title: 'Пока не срослось',
    value: 'rejected',
  },
  {
    title: 'В архиве',
    value: 'archived',
  },
];