<template>
  <RenderlessComponent>
    <slot :isLoading="isLoading" :error="error" />
  </RenderlessComponent>
</template>

<script>
  import RenderlessComponent from "src/components/RenderlessComponent/RenderlessComponent.vue";

  import { cancelRequest, handleRequestError, isCancelledRequest } from "src/services/api.js";
  import { debounce } from "src/utils/debounce.js";

  export default {
    name: 'ResourceAction',
    components: { RenderlessComponent },
    props: {
      action: {
        type: Function,
        required: true,
      },
      params: Object,
      debounce: Number,
    },
    data() {
      return {
        request: null,
        error: null,
        debounced: debounce(function(params, context) {
          this.syncDispatch(params, context);
        }, this.debounce),
        isLoading: false,
      }
    },
    methods: {
      dispatch(extraParams, { immediate, ...context } = {}) {
        this.cancel();

        this.error = null;
        const params = { ...this.params, ...extraParams };

        this.isLoading = true;
        this.$emit('update:isLoading', { value: true, params });

        if (this.debounce && !immediate) {
          this.debounced(params, context);
        } else {
          this.syncDispatch(params, context);
        }
      },
      syncDispatch(params, context) {
        const request = this.request = this.action(params);

        const teardown = () => {
          if (this.request === request) {
            this.request = null;
            this.isLoading = false;
            this.$emit('update:isLoading', { value: false, params });
          }
        };

        this.request
          .then(({ data, config }) => {
            this.$emit('success', { data, params, config, context });
          })
          .catch((error) => {
            if (!isCancelledRequest(error)) {
              this.error = error;
              this.$emit('error', { error, params });
            }
            handleRequestError(error);
          })
          .then(teardown, teardown);
      },
      cancel() {
        cancelRequest(this.request);
        this.debounced.cancel();
        this.isLoading = false;
        this.request = null;
      },
    }
  }
</script>