<template>
  <div class="inline-icons-list">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'InlineIconsList'
  };
</script>
