<template>
  <div class="no-content" :class="bem('no-content', { appearance })">
    <div
      v-if="icon"
      class="no-content__icon"
      :class="bem('no-content__icon', { appearance: iconAppearance })"
    >
      <img
        :src="icon.src"
        :width="icon.width"
        :height="icon.height"
        alt=""
      />
    </div>
    <div
      v-if="title"
      class="no-content__title"
      v-text="title"
      :class="bem('no-content__title', { appearance: titleAppearance })"
    />
    <div
      v-if="description"
      class="no-content__description"
      :class="bem('no-content__description', { appearance })"
      v-html="description"
    />
    <div
      v-if="$slots.actions"
      class="no-content__actions"
    >
      <slot name="actions" />
    </div>
    <div v-else-if="actions && actions.length !== 0" class="no-content__actions">
      <BasicButton
        v-for="action in actions"
        :href="action.href"
        :key="action.href"
        :appearance="action.appearance"
        size="m"
        v-bind="action.attrs"
        v-on="action.listeners"
      >
        {{action.title}}
      </BasicButton>
    </div>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';

  export default {
    name: 'NoContent',
    components: { BasicButton },
    props: {
      title: String,
      description: String,
      icon: Object,
      actions: Array,
      appearance: String,
      titleAppearance: String,
      iconAppearance: String,
    },
    methods: {
      bem,
    }
  }
</script>
