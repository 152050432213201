<template>
  <li
    class="user-contacts-item"
    :class="
      bem('user-contacts-item', {
        resume: isResumeCard,
        disabled: isDisabled,
      })
    "
  >
    <SvgIcon
      class="user-contacts-item__icon"
      source="/images/contacts-v3.svg"
      :icon="icon"
      :size="isResumeCard ? 20 : 24"
    />
    <LinkOrText
      v-if="isShowContacts || isResumeCard"
      target="_blank"
      appearance="blue"
      rel="noopener noreferer"
      class="user-contacts-item__text"
      :item="contactValue"
      @click.native="sendAnalytics($event)"
    />
    <BlurContact
      v-else
      :conditions="contactsConditions"
      @openContacts="$emit('openContacts')"
    />
  </li>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import BlurContact from 'src/components/BlurContact/BlurContact.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import LinkOrText from 'src/components/LinkOrText/LinkOrText.vue';

export default {
  name: 'UserContactsItem',
  components: {
    BlurContact,
    SvgIcon,
    LinkOrText,
  },
  inject: ['mediaQueries', 'contactsConditions'],
  props: {
    contact: {
      type: Object,
      required: true,
    },
    isShowContacts: {
      type: Boolean,
      default: false,
    },
    isResumeCard: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openContacts'],
  methods: {
    bem,
    sendAnalytics(event) {
      const href = event.target?.getAttribute('href');

      if (!href) return;

      const eventPostfix = this.analyticsEventPostfix;

      if (!eventPostfix) return;

      const eventName = `contacts_${eventPostfix}`;

      window.helpers.trackEventByName(eventName, {
        event_category: this.isResumeCard ? 'user_card' : 'profile',
      });
    },
  },
  computed: {
    contactValue() {
      if (this.contact.type === 'phone' && this.mediaQueries.mobile) {
        return {
          ...this.contact.value,
          title: this.contactName,
          href: !this.isDisabled ? `tel:${this.contact.value.title}` : null,
        };
      }

      return {
        ...this.contact.value,
        title: this.contactName,
      };
    },
    contactName() {
      if (!this.isResumeCard) return this.contact.value.title;

      switch (this.contact.kind) {
        case 'phone_mobile':
          return !this.isDisabled
            ? this.contact.value.title
            : this.contact.title;
        default:
          return this.contact.title;
      }
    },
    icon() {
      const customIcons = [
        'vkontakte',
        'telegram',
        'email',
        'facebook',
        'linkedin',
        'skype',
        'behance',
        'dribbble',
        'gitlab',
        'stackoverflow',
        'github',
      ];

      if (customIcons.includes(this.contact.kind)) return this.contact.kind;

      if (this.contact.type === 'phone') return 'phone';

      return 'link';
    },
    analyticsEventPostfix() {
      const { type, kind } = this.contact;
      const kindsMap = {
        vkontakte: 'social_vk',
        facebook: 'social_fb',
        linkedin: 'social_ld',
        telegram: 'tlg_tlg',
      };
      const typesMap = {
        phone: 'tel_tel',
        email: 'email_email',
        site: 'link',
      };
      const byKind = kindsMap[kind];
      const byType = typesMap[type];

      return byKind ?? byType;
    },
  },
};
</script>
