<template>
  <div class="faded-content__container">
    <div
      class="faded-content"
      :class="bem('faded-content', { minimal })"
      :style="styles"
    >
      <div
        ref="contentRef"
        class="faded-content__body"
      >
        <slot />
      </div>
      <TransitionFade>
        <div
          v-if="!showButton ? false : !visible"
          class="faded-content__fade"
        />
      </TransitionFade>
    </div>
    <div
      v-if="showButton"
      class="faded-content__toggle"
      :class="bem('faded-content__toggle', { appearance })"
    >
      <BasicButton
        :appearance="buttonAppearance"
        size="sm"
        @click="toggleExpand"
      >
        <span v-text="buttonTitle" />
      </BasicButton>
    </div>
  </div>
</template>

<script>

  import { bem } from 'src/utils/bem.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

  const FADE_HEIGHT_WITH_MARGIN = 90;

  export default {
    name: 'FadedContent',
    components: { TransitionFade, BasicButton },
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      notTransparent: Boolean,
      buttonAppearance: {
        type: String,
        default: 'pseudo-link-muted',
      },
      expandTitle: {
        type: String,
        required: true,
      },
      collapseTitle: {
        type: String,
        required: false,
      },
      appearance: {
        type: String,
        required: false,
      },
      minimal: Boolean,
    },
    emits: ['update:show'],
    data() {
      return {
        contentHeight: 0,
        visible: this.show,
      };
    },
    mounted() {
      this.contentHeight = this.$refs.contentRef.clientHeight;

      this.$refs.contentRef.querySelectorAll('img').forEach(img => {
        // eslint-disable-next-line no-param-reassign
        img.onload = () => {
          this.contentHeight = this.$refs.contentRef.clientHeight;
        }
      });
    },
    methods: {
      bem,
      toggleExpand() {
        this.visible = !this.visible;
        this.$emit('update:show', this.visible);
      }
    },
    computed: {
      styles() {
        if (this.visible) {
          return {
            maxHeight: `${this.contentHeight}px`,
          };
        }

        return null;
      },
      buttonTitle() {
        return this.visible ? this.collapseTitle : this.expandTitle;
      },
      showButton() {
        return this.contentHeight > FADE_HEIGHT_WITH_MARGIN;
      }
    },
  };
</script>
