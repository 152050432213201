<template>
  <div class="recommends-slider">
    <div
      v-if="haveRecommendations"
      class="recommends-slider__body swiper"
      ref="body"
    >
      <template v-if="recommendations.length > 1">
        <button
          class="recommends-slider__button recommends-slider__button--prev"
          ref="prev"
        >
          <span>Предыдущая рекомендация</span>
          <SvgIcon color="muted-blue" size="24" icon="arrow" />
        </button>
        <button
          class="recommends-slider__button recommends-slider__button--next"
          ref="next"
        >
          <span>Следующая рекомендация</span>
          <SvgIcon color="muted-blue" size="24" icon="arrow" />
        </button>
      </template>
      <div class="recommends-slider__slides swiper-wrapper">
        <div
          v-for="recommendation in recommendations"
          :key="recommendation"
          v-html="recommendation"
          class="swiper-slide recommends-slider__slide"
          :class="bem('recommends-slider__slide', { 'swiper-off': recommendations.length < 2 })"
        />
      </div>
    </div>

    <BasicText v-else size="medium">
      <span class="recommends-slider__full">Вау, вот это профиль!</span>
    </BasicText>
  </div>
</template>

<script>
import 'swiper/scss';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';

import { bem } from 'src/utils/bem';

export default {
  name: 'RecommendsSlider',
  components: {
    BasicText,
    SvgIcon,
  },
  props: {
    recommendations: {
      type: Array,
    },
  },
  data() {
    return {
      swiperInstance: null,
      currentIndex: 0,
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    bem,
    initSwiper() {
      if (this.recommendations.length < 2) return;

      this.swiperInstance = new Swiper(this.$refs.body, {
        modules: [Navigation, Pagination],
        autoHeight: true,
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
        loop: true,
        slidesPerView: 1,
      });

      this.swiperInstance.on('slideChange', ({ realIndex }) => {
        this.currentIndex = realIndex;
      });
    },
  },
  computed: {
    haveRecommendations() {
      return this.recommendations && this.recommendations.length;
    },
  },
};
</script>
