import { apiUrl, apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const CareerSuggestionsResource = {
  getSkills({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/skills`,
      params: {
        term: query,
      },
    });
  },
  getCompanies({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/companies`,
      params: {
        term: query,
      },
    });
  },
  getLocations({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/locations`,
      params: {
        term: query,
      },
    });
  },
  getUniversities({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/universities`,
      params: {
        term: query,
      },
    });
  },
  getEducationCenters({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/education_centers`,
      params: {
        term: query,
      },
    });
  },
  getSimilarSkills({ skillIds }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/similar_skills`,
      params: {
        skill_ids: skillIds,
      },
    });
  },
  getCities({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/cities`,
      params: {
        term: query,
      },
    });
  },
  getSimilarCities({ companyAlias, vacancyId }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/similar_cities`,
      params: {
        vacancy: vacancyId,
        company_alias: companyAlias,
      },
    });
  },
  getCountries({ type, query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/suggestions/countries`,
      params: {
        type,
        q: query,
      },
    });
  },
  getCuratorVacancies({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/suggestions/curator_vacancies`,
      params: {
        term: query,
      },
    });
  },
};

export const HabrSuggestionsResource = {
  getCompanies({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/habr_companies`,
      params: {
        term: query,
      },
    });
  },
  getLocations({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/habr_locations`,
      params: {
        term: query,
      },
    });
  },
  getHubs({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/habr_hubs`,
      params: {
        term: query,
      },
    });
  },
};

export const QASuggestionsResource = {
  getLocations({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/qa_locations`,
      params: {
        term: query,
      },
    });
  },
  getHubs({ query }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrl}/suggestions/qa_hubs`,
      params: {
        term: query,
      },
    });
  },
};

export const LanguagesSuggestionsResource = {
  getLanguages() {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/languages`,
    });
  },
  getLanguageGrades() {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/language_grades`,
    });
  },
};
