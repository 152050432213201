  <template>
  <span class="checkbox" :class="bem('checkbox', { disabled })">
    <label class="checkbox__wrapper">
      <span class="checkbox__icon-wrapper">
        &#8203;
        <input
          type="checkbox"
          class="checkbox__input visually-hidden"
          v-model="inputModel"
          :value="value"
          :false-value="falseValue"
          :disabled="disabled"
          :name="name"
        >
        <span
          class="checkbox__button"
          :class="{ 'checkbox__button_minus': minusWhenNotChecked }"
        />
      </span>
      <span class="checkbox__content">
        <slot />
      </span>
    </label>
  </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';
  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  export default {
    name: 'BasicCheckbox',
    model: modelValueConfig(),
    props: {
      modelValue: {
        type: null,
        required: true,
      },
      value: {
        type: null,
        required: true,
      },
      minusWhenNotChecked: Boolean,
      falseValue: null,
      disabled: Boolean,
      name: String,
    },
    emits: ['update:modelValue'],
    methods: {
      bem,
    },
    computed: {
      inputModel: createPropModel('modelValue'),
    }
  }
</script>