<template>
  <div class="recaptcha-field">
    <WithSpinnerPlaceholder
      full-height
      :loading="isLoading"
      :size="40"
    >
      <FormField
        @invalid="resetCaptcha"
        name="recaptcha"
        v-slot="{ reset }"
      >
        <VueRecaptcha
          ref="recaptcha"
          load-recaptcha-script
          :size="mediaQueries.mobile ? 'compact' : null"
          :sitekey="$options.sitekey"
          v-bind="$attrs"
          @verify="reset(), $emit('verify', $event)"
          @render="isLoading = false"
        />
      </FormField>
    </WithSpinnerPlaceholder>
  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';

  import FormField from 'src/components/form/FormField/FormField.vue';
  import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';

  export default {
    name: 'RecaptchaField',
    components: {
      WithSpinnerPlaceholder,
      FormField,
      VueRecaptcha
    },
    inject: ['mediaQueries'],
    sitekey: __RECAPTCHA_KEY__,
    data() {
      return {
        isLoading: true,
      }
    },
    methods: {
      resetCaptcha() {
        this.$refs.recaptcha.reset();
      },
    }
  }
</script>