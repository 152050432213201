<template>
  <div :class="bem('basic-radio-button', { disabled })">
    <input
      class="basic-radio-button__input"
      type="radio"
      v-model="inputModel"
      :value="value"
      :disabled="disabled"
      :name="name"
      :id="idValue"
      @change="(e) => $emit('change', e)"
    >
    <label
      :for="idValue"
      class="basic-radio-button__label"
    >
      <slot />
    </label>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';
  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  export default {
    name: 'BasicRadioButton',
    model: modelValueConfig(),
    props: {
      modelValue: {
        type: null,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: null,
        required: true,
      },
      name: String,
    },
    methods: {
      bem,
    },
    computed: {
      inputModel: createPropModel('modelValue'),
      idValue() {
        return `radio-${this.name}-${this.value}`;
      },
    }
  }
</script>