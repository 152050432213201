<template>
  <span class="filter-item" :class="bem('filter-item', { staticView, nowrap })">
    <span
      class="filter-item__title"
      v-text="filter.title"
    />
    <RemoveButton v-if="!staticView" @click="$emit('remove', filter)" />
  </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import RemoveButton from 'src/components/buttons/RemoveButton/RemoveButton.vue';

  export default {
    name: 'FilterItem',
    components: { RemoveButton },
    props: {
      filter: {
        type: Object,
        required: true,
      },
      nowrap: Boolean,
    },
    methods: {
      bem,
    },
    computed: {
      staticView() {
        return !('remove' in this.$listeners);
      }
    },
  }
</script>