<template>
  <div
    v-if="showResults"
    class="suggestion-results"
    @mouseover="$emit('mouseover', $event)"
  >
    <SuggestionItems
      v-if="items && items.length"
      :items="items"
      :active-suggestion="activeSuggestion"
      :scroll-to-active-suggestion="scrollToActiveSuggestion"
      :embedded="embedded"
      :max-height="maxHeight"
      appearance="compact"
      @click="$emit('click', $event)">
      <template #default="{item, active}">
        <slot :item="item" :active="active"/>
      </template>
      <template
        v-if="$slots.beforeItems"
        #beforeItems
      >
        <slot name="beforeItems" />
      </template>
    </SuggestionItems>
    <div v-else class="suggestion-results__empty">
      Ничего не найдено
    </div>
  </div>
</template>

<script>
  import SuggestionItems from 'src/components/suggestions/SuggestionItems/SuggestionItems.vue';

  export default {
    name: 'SuggestionResults',
    components: { SuggestionItems },
    props: {
      items: Array,
      activeSuggestion: Object,
      scrollToActiveSuggestion: Boolean,
      embedded: Boolean,
      maxHeight: Number,
      showEmptyResult: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['click', 'mouseover'],
    computed: {
      showResults() {
        return (this.items && this.items.length) || (this.showEmptyResult);
      }
    }
  }
</script>
