<template>
  <div class="job-experience-item">
    <WithAvatarAside :href="company.href" :image="company.image">
      <div
        class="job-experience-item__header"
        :class="
          bem('job-experience-item__header', {
            centered: shouldCenterTitle,
            rate: Boolean(company.rateHref),
          })
        "
      >
        <BasicText weight="bold" size="large">
          <BasicLink
            v-if="company.href"
            :href="company.href"
            :title="company.title"
            appearance="dark"
          />
          <BasicText v-else color="muted">
            {{ company.title }}
          </BasicText>
        </BasicText>
        <CompanyIcons :company="company" />
        <div v-if="company.rating" class="job-experience-item__rating">
          <CompanyRating :rating="company.rating" />
        </div>
        <div v-if="company.rateHref" class="job-experience-item__rate">
          <CompanyScoreLink
            :rate-href="company.rateHref"
            ga-label="user_experience_main"
          />
        </div>
      </div>
      <div
        v-if="company.subtitle"
        class="job-experience-item__subtitle"
        v-text="company.subtitle"
      />
      <div v-if="meta.length !== 0" class="job-experience-item__meta">
        <InlineList :items="meta" v-slot="{ item }">
          <BasicLink
            v-if="item.href"
            :href="item.href"
            :title="item.title"
            appearance="dark"
          />
          <span v-else v-text="item.title" />
        </InlineList>
      </div>
      <div
        v-if="showSingleTimelineItem"
        class="job-experience-item__short-info"
      >
        <JobPosition :position="company.positions[0]" />
      </div>
    </WithAvatarAside>
    <div
      v-if="company.positions && !showSingleTimelineItem"
      class="job-experience-item__positions"
    >
      <ConnectedTimeline
        :items="company.positions"
        :get-key="(position) => position.title"
        v-slot="{ item }"
      >
        <JobPosition :position="item" />
      </ConnectedTimeline>
    </div>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import BasicLink from 'src/components/BasicLink/BasicLink.vue';
import CompanyIcons from 'src/components/companies/CompanyIcons/CompanyIcons.vue';
import CompanyScoreLink from 'src/components/companies/CompanyScoreLink/CompanyScoreLink.vue';
import ConnectedTimeline from 'src/components/ConnectedTimeline/ConnectedTimeline.vue';
import InlineList from 'src/components/InlineList/InlineList.vue';
import CompanyRating from 'src/components/resume/CompanyRating/CompanyRating.vue';
import JobPosition from 'src/components/resume/JobPosition/JobPosition.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import WithAvatarAside from 'src/components/WithAvatarAside/WithAvatarAside.vue';

export default {
  name: 'JobExperienceItem',
  components: {
    CompanyIcons,
    CompanyScoreLink,
    JobPosition,
    ConnectedTimeline,
    InlineList,
    CompanyRating,
    BasicLink,
    BasicText,
    WithAvatarAside,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bem,
  },
  computed: {
    meta() {
      const { company } = this;
      const result = [];

      if (company.location) {
        result.push({
          title: company.location,
        });
      }

      if (company.users) {
        result.push({
          title: company.users.title,
          href: company.users.href,
        });
      }

      return result;
    },
    showSingleTimelineItem() {
      return !this.hasDescription && this.company.positions?.length === 1;
    },
    shouldCenterTitle() {
      return !this.hasDescription && this.company.positions?.length > 1;
    },
    hasDescription() {
      return this.company.subtitle || this.meta.length;
    },
  },
};
</script>
