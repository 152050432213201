<template>
  <div
    class="approve-button"
    :class="bem('approve-button', { approved: modelValue.hasApproved })"
  >
    
    <ResourceAction
      ref="getVotersActionRef"
      :action="getVotersAction"
      :params="{ letterId }"
      @success="onVotersGetSuccess"
      v-slot="{ isLoading }"
    >
      <ResourceAction
        ref="approveActionRef"
        :action="approveAction"
        :params="{ letterId, userId }"
        @success="onApproveSuccess"
      >
        <div>
          <div class="approve-button__wrapper">
            <BasicButton
              :href="fallback"
              appearance="approve"
              @click="onApproveToggle"
            >
              Согласен
            </BasicButton>
            <div class="approve-button__separator" />
            <BasicButton
              :disabled="modelValue.total === 0"
              appearance="approve"
              @click="showVoters"
            >
              {{ modelValue.total }}
            </BasicButton>
          </div>
  
          <TransitionFade body-scroll-lock>
            <ModalWindow
              v-if="overlayVisible"
              title="Согласны с рекомендательным письмом"
              @close="overlayVisible = false"
            >
              <SectionBox shadow="none">
                <BasicSection appearance="horizontal">
                  <WithSpinnerPlaceholder :loading="isLoading">
                    <SeparatedList
                      :items="voters"
                      :get-key="(_item) => _item.href"
                      v-slot="{ item }"
                    >
                      <UserListItem :user="item" />
                    </SeparatedList>
                  </WithSpinnerPlaceholder>
                </BasicSection>
              </SectionBox>
            </ModalWindow>
          </TransitionFade>
        </div>
      </ResourceAction>
    </ResourceAction>
    
  </div>
</template>

<script>
  import { PersonalResource } from 'src/services/personal.js';
  import { bem } from 'src/utils/bem.js';
  import { modelValueConfig } from 'src/utils/vue.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
  import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
  import ResourceAction from 'src/components/ResourceAction/ResourceAction.vue';
  import SectionBox from 'src/components/SectionBox/SectionBox.vue';
  import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
  import SeparatedList from 'src/components/SeparatedList/SeparatedList.vue';
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';
  import UserListItem from 'src/components/users/UserListItem/UserListItem.vue';
  
  export default {
    name: 'ApproveButton',
    components: { SectionBox, WithSpinnerPlaceholder, BasicSection, UserListItem, SeparatedList, ModalWindow, TransitionFade, ResourceAction, BasicButton },
    model: modelValueConfig(),
    props: {
      modelValue: {
        type: Object,
        required: true,
      },
      userId: {
        type: String,
        required: true,
      },
      letterId: {
        type: Number,
        required: true,
      },
      fallback: String,
    },
    data() {
      return {
        voters: null,
        overlayVisible: false,
      }
    },
    methods: {
      bem,
      approveAction: PersonalResource.toggleRecommendationLetterApprove,
      getVotersAction: PersonalResource.getRecommendationLetterVoters,
      onApproveToggle(event) {
        if (this.fallback) return;
        
        event.preventDefault();
        
        this.$refs.approveActionRef.dispatch();
      },
      onApproveSuccess({ data: { approve } }) {
        this.voters = null;
        this.$emit('update:modelValue', approve);
      },
      onVotersGetSuccess({ data: { voters } }) {
        this.voters = voters;
        this.overlayVisible = true;
      },
      showVoters() {
        this.overlayVisible = true;
        if (!this.voters) {
          this.$refs.getVotersActionRef.dispatch();
        }
      },
    }
  }
</script>