<template>
  <ModalWindow :title="title" @close="$emit('close')">
    <SectionBox shadow="none">
      <BasicSection appearance="always-same-paddings">
        <slot />
      </BasicSection>
    </SectionBox>
    <SubmitBox>
      <BasicSection appearance="submit">
        <ButtonGroup appearance="inline">
          <BasicButton
            :appearance="redConfirmButton ? 'danger' : 'primary'"
            size="l"
            @click="$emit('confirm')"
          >
            {{ confirmLabel }}
          </BasicButton>
          <BasicButton appearance="secondary" size="l" @click="$emit('close')">
            Отменить
          </BasicButton>
        </ButtonGroup>
      </BasicSection>
    </SubmitBox>
  </ModalWindow>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import SectionBox from 'src/components/SectionBox/SectionBox.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
import SubmitBox from 'src/components/SubmitBox/SubmitBox.vue';

export default {
  name: 'BasicConfirm',
  components: {
    ButtonGroup,
    BasicButton,
    SectionBox,
    BasicSection,
    ModalWindow,
    SubmitBox,
  },
  props: {
    title: String,
    redConfirmButton: {
      type: Boolean,
      default: false,
    },
    confirmLabel: {
      type: String,
      default: 'Подтвердить',
    },
  },
  emits: ['close', 'confirm'],
};
</script>
