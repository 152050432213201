<template>
  <div class="notice-card" :class="bem('notice-card', { appearance })">
    <img
      v-if="image"
      :src="image.src"
      :alt="image.alt || ''"
      :width="image.width"
      :height="image.height"
      class="notice-card__image"
    />
    <div v-if="title" class="notice-card__title" v-text="title" />
    <div
      v-if="$slots.description || description"
      class="notice-card__description"
    >
      <slot v-if="$slots.description" name="description" />
      <div v-else v-text="description" />
    </div>
    <div v-if="$slots.actions" class="notice-card__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

export default {
  name: 'NoticeCard',
  props: {
    title: String,
    description: String,
    image: Object,
    appearance: {
      type: String,
      default: null,
      validator: (value) =>
        !value || ['no-content', 'no-content-new'].includes(value),
    },
  },
  methods: {
    bem,
  },
};
</script>
