<template>
  <span class="opposite-text">
    <span class="opposite-text__start" v-text="left" />
    <span class="opposite-text__end" v-text="right" />
  </span>
</template>

<script>
  export default {
    name: 'OppositeText',
    props: {
      left: {
        type: [String, Number],
        required: true,
      },
      right: {
        type: [String, Number],
        required: true,
      },
    }
  }
</script>