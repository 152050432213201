<template>
  <NoContent
    :icon="icon"
    :title="placeholder.title"
    :description="placeholder.description"
  >
    <template #actions>
      <BasicButton
        v-if="placeholder.action"
        :href="placeholder.action.href"
        appearance="primary"
        size="l"
      >
        {{ placeholder.action.title }}
      </BasicButton>
    </template>
  </NoContent>
</template>

<script>
  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import NoContent from 'src/components/placeholders/NoContent/NoContent.vue';
  
  export default {
    name: 'UserResumePlaceholder',
    components: { BasicButton, NoContent },
    props: {
      placeholder: {
        type: Object,
        required: true,
      },
      icon: {
        type: Object,
        required: true,
      }
    }
  }
</script>