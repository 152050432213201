<template>
  <div class="blur-contact">
    <BasicTooltip
      :content="tooltipContent"
      placement="top"
      :max-width="248"
      theme="dark"
      interactive
    >
      <div
        class="blur-contact-item"
        :style="{ width: !width ? '100%' : `${width}px` }"
      />
    </BasicTooltip>
  </div>
</template>

<script>
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';

export default {
  name: 'BlurContact',
  components: { BasicTooltip },
  props: {
    width: {
      type: Number,
      default: 0,
    },
    conditions : {
      type: Object,
      required: true
    }
  },
  emits: ['openContacts'],
  methods: {
    openContacts() {
      this.$emit('openContacts');
    },
  },
  computed: {
    tooltipContent() {
      if (!this.conditions) return '';
      
      const tooltipHtml = document.createElement('div');
      tooltipHtml.innerHTML = `${this.conditions.description} `;
      if (this.conditions.action.type === 'button') {
        const tooltipButton = document.createElement('button');
        tooltipButton.onclick = this.openContacts;
        tooltipButton.type = 'button';
        tooltipButton.innerHTML = this.conditions.action.title;
        tooltipHtml.appendChild(tooltipButton);
      } else {
        const tooltipLink = document.createElement('a');
        tooltipLink.href = this.conditions.action.href;
        tooltipLink.innerHTML = this.conditions.action.title;
        tooltipHtml.appendChild(tooltipLink);
      }
      return tooltipHtml;
    },
  },
};
</script>