<template>
  <div class="outer-click" ref="root">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'OuterClick',
    props: {
      event: {
        type: String,
        default: 'click',
      },
      capture: Boolean,
    },
    emits: ['click'],
    mounted() {
      document.addEventListener(this.event, this.onClick, this.capture);
    },
    beforeDestroy() {
      document.removeEventListener(this.event, this.onClick, this.capture);
    },
    methods: {
      onClick(event) {
        if (this.$refs.root.contains(event.target)) return;
        this.$emit('click', event);
      },
    }
  }
</script>
