<template>
  <div class="achievements-sidebar-card">
    <SidebarSection
      title="Награды"
      :more-link-href="moreLinkHref"
      :more-link-title="moreLinkTitle"
    >
      <template #afterTitle>
        <a
          class="achievements-sidebar-card__help"
          href="/info/achievements"
          title="Помощь"
          target="_blank"
        >
          <span class="icon icon-question" />
        </a>
      </template>
      <template #body>
        <div class="achievements-sidebar-card__body">
          <img
            v-for="achievement in achievementsSlice"
            :key="achievement.icon"
            :src="achievement.icon"
            :alt="achievement.title"
            :title="achievement.description"
            width="50"
            height="50"
            class="achievements-sidebar-card__image"
          />
        </div>
      </template>
    </SidebarSection>
  </div>
</template>

<script>
import SidebarSection from 'src/components/sidebar/SidebarSection/SidebarSection.vue';

const ACHIEVEMENTS_SPOILER_COUNT = 4;

export default {
  name: 'AchievementsSidebarCard',
  components: {
    SidebarSection,
  },
  props: {
    achievements: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    achievementsSlice() {
      return this.achievements.slice(0, ACHIEVEMENTS_SPOILER_COUNT);
    },
    moreLinkHref() {
      return `${this.user.achievementsPage}#achievements`;
    },
    moreLinkTitle() {
      if (this.user.isOwner) {
        return `Все награды (${this.achievements.length} из ${this.user.achievementsTotal})`;
      }

      if (this.achievements.length > ACHIEVEMENTS_SPOILER_COUNT) {
        return `Все награды (${this.achievements.length})`;
      }
      
      return 'Все награды';
    },
  },
};
</script>
