<template>
  <button
    class="suggestion-item"
    :class="bem('suggestion-item', { active, centered })"
    @click="$emit('click', item)"
    :type="itemIsNotButton ? null : 'button'"
  >
    <span v-if="multiple" class="suggestion-item__checkbox">
      <BasicCheckbox
        :value="null"
        :model-value="selected"
        @update:modelValue="$emit('click', item)"
        :minus-when-not-checked="minusWhenNotChecked"
      />
    </span>
    <span
      v-if="item.image"
      class="suggestion-item__image-container"
      :class="bem('suggestion-item__image-container', { size: imageSize })"
    >
      <img
        class="suggestion-item__image"
        :class="bem('suggestion-item__image', { round: roundImage })"
        :src="item.image.src"
        :alt="item.image.alt"
        :srcset="
          item.image.src2x
            ? `${item.image.src} 1x, ${item.image.src2x} 2x`
            : null
        "
      />
    </span>
    <span v-if="item.icon" class="suggestion-item__image-container--size-icon">
      <img class="suggestion-item__image" :src="item.icon" :alt="item.title" />
    </span>
    <span class="suggestion-item__main">
      <span class="suggestion-item__title" v-text="item.title" />
      <span
        v-if="item.subtitle"
        class="suggestion-item__subtitle"
        v-text="item.subtitle"
      />
    </span>
    <div v-if="$slots.after" class="suggestion-item__after">
      <slot name="after" />
    </div>
  </button>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import BasicCheckbox from 'src/components/form/BasicCheckbox/BasicCheckbox.vue';

export default {
  name: 'SuggestionItem',
  components: { BasicCheckbox },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: Boolean,
    selected: Boolean,
    multiple: Boolean,
    itemIsNotButton: Boolean,
    minusWhenNotChecked: Boolean,
    imageSize: {
      type: String,
      default: null,
    },
    roundImage: Boolean,
    centered: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    bem,
  },
};
</script>
