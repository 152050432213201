<template>
  <component
    :is="embedded ? 'div' : 'SectionGroup'"
    gap="medium"
    :class="embedded && 'suggestions-picker-embed'"
  >
    <ResourceAction
      :action="action"
      :params="params"
      ref="action"
      :debounce="400"
      @success="onSuccess"
      v-slot="{ isLoading }"
    >
      <div v-if="embedded" class="suggestions-picker__embed-content" ref="embed">
        <TextInput
          ref="input"
          v-model="query"
          appearance="dropdown-search-embedded"
          :placeholder="searchPlaceholder"
          :disabled="disabled"
          @blur="onBlur"
          @focus="onFocus"
          @keyup.up="onUp"
          @keyup.down="onDown"
          @keyup.enter="onEnter"
        >
          <template #icon>
            <SvgIcon icon="search" size="24" />
          </template>
        </TextInput>
        <WithSpinnerPlaceholder
          v-if="isLoading || forceLoadingState || allItems"
          ref="spinner"
          :loading="isLoading || forceLoadingState"
          :style="{ flexGrow: 1 }"
        >
          <SuggestionResults
            :items="allItems"
            :selected="selectedItems"
            :embedded="embedded"
            :active-suggestion="activeSuggestion"
            :max-height="maxSuggestionsHeight"
            scroll-to-active-suggestion
            :item-is-not-button="suggestionResultIsNotButton"
            @mouseover="onMouseover"
          >
            <template #default="{ item, active }">
              <slot
                :item="item"
                :active="active"
                :on-click="onClick"
                :selected="selectedValues.has(item.value)"
              >
                <SuggestionItem
                  image-size="small"
                  :multiple="multiple"
                  :item="item"
                  :active="active"
                  :round-image="roundItemImage"
                  :selected="selectedValues.has(item.value)"
                  :item-is-not-button="suggestionResultIsNotButton"
                  @click="onClick"
                />
              </slot>
            </template>
          </SuggestionResults>
        </WithSpinnerPlaceholder>
      </div>
      <SearchInDropdown
        v-else-if="isSearchInDropdown"
        v-model="query"
        :placeholder="placeholder"
        :label="firstValueLabel"
        autocomplete="off"
        :disabled="disabled"
        :invalid="invalid"
        @blur="onBlur"
        @focus="onFocus"
        @keyup.up="onUp"
        @keyup.down="onDown"
        @keyup.enter="onEnter"
        @hide="updateListHead(); resetQueryState()"
        :search-placeholder="searchPlaceholder"
      >
        <template #label-aside>
          <slot name="label-aside" />
        </template>
        <template #label-rest>
          <slot name="label-rest" />
        </template>
        <WithSpinnerPlaceholder
          v-if="isQueryValid || allItems && allItems.length"
          :loading="isLoading || forceLoadingState"
        >
          <SuggestionResults
            :items="allItems"
            :selected="selectedItems"
            :active-suggestion="activeSuggestion"
            :item-is-not-button="suggestionResultIsNotButton"
            scroll-to-active-suggestion
            @mouseover="onMouseover"
          >
            <template
              v-if="$slots.beforeItems"
              #beforeItems
            >
              <slot name="beforeItems" />
            </template>
            <template #default="{ item, active }">
              <slot
                :item="item"
                :active="active"
                :on-click="onClick"
                :selected="selectedValues.has(item.value)"
              >
                <SuggestionItem
                  image-size="small"
                  :multiple="multiple"
                  :item="item"
                  :active="active"
                  :round-image="roundItemImage"
                  :selected="selectedValues.has(item.value)"
                  :item-is-not-button="suggestionResultIsNotButton"
                  @click="onClick"
                />
              </slot>
            </template>
          </SuggestionResults>
        </WithSpinnerPlaceholder>
      </SearchInDropdown>

      <SearchWithDropdown
        v-else
        v-model="query"
        ref="search"
        :placeholder="placeholder"
        autocomplete="off"
        :disabled="disabled"
        :invalid="invalid"
        @blur="onBlur"
        @focus="onFocus"
        @keyup.up="onUp"
        @keyup.down="onDown"
        @keyup.enter="onEnter"
      >
        <WithSpinnerPlaceholder
          v-if="isQueryValid"
          :loading="isLoading || forceLoadingState"
        >
          <SuggestionResults
            :items="suggestions"
            :active-suggestion="activeSuggestion"
            scroll-to-active-suggestion
            :show-empty-result="showEmptyResult"
            :item-is-not-button="suggestionResultIsNotButton"
            @click="onClick"
            @mouseover="onMouseover"
          />
        </WithSpinnerPlaceholder>
      </SearchWithDropdown>
    </ResourceAction>
    <FiltersList
      v-if="!isSearchInDropdown && displaySelected && selectedItems && selectedItems.length !== 0"
      :filters="selectedItems"
      @remove="onRemove"
    />
  </component>
</template>

<script>

  import { resolveCircularIndex, rotateIndex } from 'src/utils/math.js';
  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  import FiltersList from 'src/components/filters/FiltersList/FiltersList.vue';
  import TextInput from 'src/components/form/TextInput/TextInput.vue';
  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
  import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
  import ResourceAction from 'src/components/ResourceAction/ResourceAction.vue';
  import SearchInDropdown from 'src/components/search/SearchInDropdown/SearchInDropdown.vue';
  import SearchWithDropdown from 'src/components/search/SearchWithDropdown/SearchWithDropdown.vue';
  import SectionGroup from 'src/components/sections/SectionGroup/SectionGroup.vue';
  import SuggestionItem from 'src/components/suggestions/SuggestionItem/SuggestionItem.vue';
  import SuggestionResults from 'src/components/suggestions/SuggestionResults/SuggestionResults.vue';

  export default {
    name: 'SuggestionsPicker',
    components: {
      TextInput,
      SvgIcon,
      SuggestionItem,
      SearchInDropdown,
      FiltersList,
      SectionGroup,
      ResourceAction,
      SuggestionResults,
      WithSpinnerPlaceholder,
      SearchWithDropdown,
    },
    model: modelValueConfig(),
    props: {
      action: {
        type: Function,
        required: true
      },
      modelValue: [String, Number, Array],
      models: Array,
      alwaysOnTop: {
        type: Array,
        default: () => ([])
      },
      placeholder: String,
      multiple: Boolean,
      embedded: Boolean,
      autoload: {
        type: Boolean,
        default: true
      },
      forceLoadingState: Boolean,
      displaySelected: {
        type: Boolean,
        default: true
      },
      minQueryLength: {
        type: Number,
        default: 0
      },
      appearance: {
        type: String,
        default: ''
      },
      additionalParams: {
        type: Object,
        default: () => ({})
      },
      roundItemImage: Boolean,
      disabled: Boolean,
      searchPlaceholder: {
        type: String,
        default: 'Поиск'
      },
      isClearResult: {
        type: Boolean,
        default: true,
      },
      preloaded: Boolean,
      showEmptyResult: {
        type: Boolean,
        default: true,
      },
      invalid: Boolean,
      successHandler: {
        type: Function,
        default: (data) => data,
      },
      suggestionResultIsNotButton: {
        type: Boolean,
        default: false,
      },
      defaultQuery: {
        type: String,
        default: null,
      }
    },
    emits: ['update:models', 'update:modelValue', 'update:query', 'onBlur'],
    data() {
      return {
        query: this.defaultQuery,
        suggestions: this.preloaded ? [...this.models] : null,
        activeSuggestionIndex: null,
        currentSelection: [],
        currentRemovedSelection: new Set(),
        listHead: [],
        alwaysOnTopItems: this.alwaysOnTop,
        maxSuggestionsHeight: 290,
        doNotUpdateListHead: false,
        waitForQuery: false,
      };
    },
    watch: {
      query(value, oldValue) {
        this.waitForQuery = !value && oldValue
        this.$nextTick(() => {
          if (!value && !this.autoload) {
            this.cancelLoading();
            this.$emit('update:query', value);
            return;
          }

          if (!this.isQueryValid) {
            this.cancelLoading();
            return;
          }

          this.loadSuggestions();
          this.$emit('update:query', value);
          this.$forceUpdate();
        });
      },
      modelValue(newValue) {
        if (this.doNotUpdateListHead) return;
        
        this.listHead = [];
        
        if (newValue) {
          if (Array.isArray(newValue)) {
            newValue.forEach(this.addValueToHead);
          } else {
            this.addValueToHead(newValue);
          }
        }
      },
    },
    mounted() {
      if (this.embedded && this.autoload) {
        this.loadSuggestions();
      }

      if (this.embedded) {
        this.maxSuggestionsHeight = this.$refs.spinner.$el.getBoundingClientRect().height;
      }
      
      if (typeof this.modelValue === 'string' && this.modelValue.length > 0) {
        this.query = this.modelValue;
      }
    },
    methods: {
      resetQueryState() {
        this.$nextTick(() => {
          this.waitForQuery = false;
        });
      },
      updateListHead() {
        this.listHead = this.listHead.filter(item => !this.currentRemovedSelection.has(item.value));
        this.currentRemovedSelection.clear();
        this.listHead.push(...this.currentSelection.filter(item => !this.listHeadValues.has(item.value)));
        this.currentSelection = [];
      },
      addValueToHead(value) {
        if (!this.listHeadValues.has(value) && this.models) {
          this.listHead.push(this.models.find(model => model.value === value));
        }
      },
      cancelLoading() {
        this.suggestions = null;
        this.$refs.action.cancel();
      },
      loadSuggestions() {
        if (this.isQueryValid) {
          return this.$refs.action.dispatch();
        }

        return Promise.resolve();
      },
      onSuccess({ data }) {
        const { list, meta = {} } = this.successHandler(data);
        this.waitForQuery = false;
        this.suggestions = list;
        this.activeSuggestionIndex = null;

        if (meta.always_on_top) {
          this.alwaysOnTopItems = meta.always_on_top;
        }
      },
      onClick(item) {
        const { models } = this;
        if (!models) {
          this.$emit('update:models', [item]);
        } else if (!models.some(({ value }) => value === item.value)) {
          this.$emit('update:models', [...models, item]);
        }

        this.updateModelValue(item);
        this.afterClick(item);

        this.doNotUpdateListHead = true;
        this.$nextTick()
          .then(() => this.$nextTick())
          .then(() => {
            this.doNotUpdateListHead = false
          })
      },
      afterClick(item) {
        if (!this.isSearchInDropdown && !this.embedded) {
          this.query = this.isClearResult ? null : item.title;
          this.$refs.search.hideDropdown();
        }
      },
      updateModelValue(item) {
        const { modelValue = [] } = this;
        let newModelValue;

        if (!this.multiple) {
          newModelValue = item.value;
        } else {
          const valueIndex = modelValue.findIndex(value => value === item.value);

          if (valueIndex === -1) {
            newModelValue = [...modelValue, item.value];
            this.currentSelection.push(item);
            this.currentRemovedSelection.delete(item.value);
          } else if (this.isSearchInDropdown) {
            this.currentRemovedSelection.add(item.value);
            const index = this.currentSelection.findIndex(selectedItem => selectedItem.value === item.value);
            if (index !== -1) {
              this.currentSelection.splice(index, 1);
            }
            newModelValue = [...modelValue];
            newModelValue.splice(valueIndex, 1);
          }
        }
        this.$emit('update:modelValue', newModelValue);
      },
      onRemove(item) {
        const { modelValue } = this;
        if (this.multiple) {
          this.$emit('update:modelValue', modelValue.filter(value => value !== item.value));
        } else {
          this.$emit('update:modelValue', null);
        }
      },
      onFocus() {
        if (!this.suggestions && this.autoload) {
          this.loadSuggestions();
        }
      },
      onBlur() {
        this.$emit('onBlur', { query: this.query });
      },
      onUp() {
        this.moveSelection(-1);
      },
      onDown() {
        this.moveSelection(1);
      },
      moveSelection(step) {
        const { activeSuggestionIndex } = this;
        if (!this.allItemsCount) return;

        if (this.$refs.search) {
          this.$refs.search.showDropdown();
        }

        const nextCircularIndex = rotateIndex(activeSuggestionIndex, step);
        this.activeSuggestionIndex = resolveCircularIndex(nextCircularIndex, this.allItemsCount);
      },
      onEnter() {
        const suggestion = this.activeSuggestion;
        if (!suggestion) return;
        this.onClick(suggestion);

        if (!this.isSearchInDropdown) {
          this.activeSuggestionIndex = null;
        }
      },
      onMouseover() {
        this.activeSuggestionIndex = null;
      },
    },
    computed: {
      isSearchInDropdown() {
        return this.appearance === 'search-in-dropdown';
      },
      model: createPropModel(),
      activeSuggestion() {
        const index = this.activeSuggestionIndex;
        if (index === null) return null;
        return this.allItems && this.allItems[index];
      },
      allItemsCount() {
        return this.allItems && this.allItems.length;
      },
      allItems() {
        if (this.query || this.waitForQuery) return this.suggestions;

        const items = this.suggestions
          ? this.listHead.concat(this.suggestions.filter(item => !this.listHeadValues.has(item.value)))
          : this.listHead;

        return this.alwaysOnTopItems.concat(items.filter(item => !this.alwaysOnTopItems.some(existingItem => existingItem.value === item.value)));
      },
      listHeadValues() {
        return new Set(this.listHead.map(item => item.value));
      },
      selectedValues() {
        return new Set(this.selectedItems.map(item => item.value));
      },
      selectedItems() {
        const { models, modelValue, multiple, alwaysOnTopItems } = this;
        if (!models || !modelValue) return null;
        if (multiple) {
          return modelValue.map(value => {
            let item = models.find(model => model.value === value);
            if (!item && alwaysOnTopItems) {
              item = alwaysOnTopItems.find(model => model.value === value);
            }
            return item;
          }).filter(Boolean);
        }
        return [models.find(({ value }) => value === modelValue)].filter(Boolean);
      },
      firstValueLabel() {
        return this.selectedItems && this.selectedItems[0] && this.selectedItems[0].title;
      },
      params() {
        return {
          ...this.additionalParams,
          query: this.query
        };
      },
      isQueryValid() {
        const length = this.query
          ? this.query.length
          : 0;

        return length >= this.minQueryLength;
      }
    }
  };
</script>
