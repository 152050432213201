<template>
  <div class="resume-job-experience">
    <SeparatedList
      :items="companies"
      v-slot="{ item }"
    >
      <JobExperienceItem :company="item" />
    </SeparatedList>
  </div>
</template>

<script>
  import JobExperienceItem from "src/components/resume/JobExperienceItem/JobExperienceItem.vue";
  import SeparatedList from "src/components/SeparatedList/SeparatedList.vue";

  export default {
    name: 'ResumeJobExperience',
    components: { JobExperienceItem, SeparatedList },
    props: {
      companies: {
        type: Array,
        required: true,
      }
    }
  }
</script>