<template>
  <InlineList
    :items="filters"
    :separator="$listeners.remove ? 'space' : null"
    :get-key="(filter) => filter.id"
    v-slot="{ item }"
  >
    <FilterItem
      :filter="item"
      :nowrap="nowrap"
      v-on="$listeners"
    />
  </InlineList>
</template>

<script>
  import FilterItem from 'src/components/filters/FilterItem/FilterItem.vue';
  import InlineList from 'src/components/InlineList/InlineList.vue';

  export default {
    name: 'FiltersList',
    components: { InlineList, FilterItem },
    props: {
      filters: {
        type: Array,
        required: true,
      },
      nowrap: Boolean,
    },
  }
</script>