<template>
  <div
    class="with-sidebar"
    :class="bem('with-sidebar', { gap })"
  >
    <div class="with-sidebar__sidebar" :class="bem('with-sidebar__sidebar', { placement, collapse })">
      <slot name="sidebar" />
    </div>
    <div class="with-sidebar__main">
      <slot />
    </div>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'WithSidebar',
    props: {
      placement: {
        type: String,
        required: true,
      },
      collapse: {
        type: String,
        required: true,
      },
      gap: {
        type: String,
        default: 'medium'
      }
    },
    methods: {
      bem,
    }
  }
</script>