<template>
  <div
    class="skills-list-show"
    :class="bem('skills-list-show', { profile: isProfile })"
  >
    <template v-for="skill in skills">
      <div
        v-if="!skill.approvedSkill"
        :key="skill.href"
        class="skills-list-show__item"
      >
        {{ skill.title }}
      </div>
      <BasicTooltip
        v-else
        :key="skill.href"
        ref="tooltip"
        content="Навык подтвержден тестом"
        :max-width="280"
        theme="dark"
      >
        <div
          class="skills-list-show__item skills-list-show__item--approved"
          @click="openSkillResult()"
        >
          {{ skill.title }}
          <SvgIcon
            class="skills-list-show__icon"
            icon="check-approved"
            color="muted"
            size="24"
          />
        </div>
      </BasicTooltip>
    </template>
    <SkillResultModal
      :modal-info="modalInfo"
      :is-modal-visible="isShowModal"
      @close="close"
    />
  </div>
</template>

<script>
import { handleRequestError } from 'src/services/api.js';
import { SkillTestResource } from 'src/services/skill-test.js';
import { bem } from 'src/utils/bem.js';

import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import SkillResultModal from 'src/components/SkillResultModal/SkillResultModal.vue';

export default {
  name: 'SkillsListShow',
  components: {
    SkillResultModal,
    SvgIcon,
    BasicTooltip,
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
    userLogin: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowModal: false,
      modalInfo: null,
    };
  },
  methods: {
    bem,
    async openSkillResult() {
      try {
        const skillTestId = 311;
        const response = await SkillTestResource.getSkillResult({ id: skillTestId, login: this.userLogin });
        this.modalInfo = response.data;
        this.isShowModal = true;
        this.$refs.tooltip.forEach((tooltip) => {
          tooltip.hide();
        });
      } catch (error) {
        handleRequestError(error);
      }
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>
