<template>
  <div class="profile-comparison">
    <div class="profile-comparison__graph">
      <div class="profile-comparison__graph-items">
        <div
          v-for="(item, index) in graphItems"
          class="profile-comparison__graph-item"
          :key="index"
          :style="{ height: `${item}%` }"
        />
      </div>
      <div
        class="profile-comparison__user-progress"
        :style="{ left: `${progress}%` }"
      >
        <img
          class="profile-comparison__avatar"
          width="25"
          height="25"
          :src="avatarSrc"
          alt="Ваш аватар"
        />
      </div>
    </div>
    <div class="profile-comparison__description" v-text="description" />
  </div>
</template>

<script>
export default {
  name: 'ProfileComparison',

  props: {
    description: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    graphItems: {
      type: Array,
      required: true,
    },
    avatarSrc: {
      type: String,
      required: true,
    },
  },
};
</script>
