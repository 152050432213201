<template>
  <div class="expert-stats">
    <div class="expert-stats-counter">
      <SvgIcon
        icon="consultations"
        size="24"
        :color="connectionsColor"
      />
      <span class="expert-stats-counter__count">
          {{ score.connections }}
        </span>
      <span class="expert-stats-counter__text">
          {{ consultsText }}
        </span>
    </div>
    <div
      v-if="score.scoresCount"
      class="expert-stats-counter expert-stats-counter--rating"
    >
      <div class="expert-profile__rating">
        <SvgIcon icon="star-small" size="24" />
        <span class="expert-stats-counter__count expert-stats-counter__count--rating">
            {{ score.averageScore }}
          </span>
      </div>
      <span class="expert-stats-counter__text expert-stats-counter__text--rating">
          {{ score.scoresCount }} {{ scoresText }}
        </span>
    </div>
  </div>
</template>

<script>
  import { getRussianPluralDeclension } from 'src/utils/translation.js';

  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

  export default {
    name: 'ExpertStats',
    components: { SvgIcon },
    props: {
      score: {
        type: Object,
        default: () => null,
      },
    },

    computed: {
      consultsText() {
        return getRussianPluralDeclension(
          'консультация',
          'консультации',
          'консультаций',
        )(this.score.connections)
      },
      scoresText() {
        return getRussianPluralDeclension(
          'оценка',
          'оценки',
          'оценок',
        )(this.score.scoresCount)
      },
      connectionsColor() {
        return this.score.connections ? 'ui-green' : 'muted';
      },
    }
  };
</script>
