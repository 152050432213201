<template>
  <ModalWindow :has-header="false" appearance="new" @close="$emit('close')">
    <div class="limit-modal">
      <div class="limit-modal__header">{{ title }}</div>
      <div class="limit-modal__description">
        {{ modalInfo.description }}
      </div>
      <div class="limit-modal__actions">
        <BasicButton
          :href="modalInfo.action.href"
          :appearance="modalInfo.action.appearance"
          size="m"
          rel="nofollow"
        >
          {{ modalInfo.action.title }}
        </BasicButton>
        <BasicButton appearance="secondary" size="m" @click="$emit('close')"
          >Отменить
        </BasicButton>
      </div>
    </div>
  </ModalWindow>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';

export default {
  name: 'LimitModal',
  components: {
    BasicButton,
    ModalWindow,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    modalInfo: {
      type: Object,
    },
  },
  emits: ['close'],
};
</script>
