<template>
  <div class="overlay overlay--open">
    <div class="overlay__wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BasicOverlay',
  }
</script>