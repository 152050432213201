<template>
  <ModalWindow :has-header="false" appearance="new" @close="closeModal($event)">
    <div
      class="decorated-result-modal__image"
      :class="bem('decorated-result-modal__image', { appearance })"
    >
      <svg
        v-if="appearance === 'warning'"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 28H84V75C84 77.7614 81.7614 80 79 80H17C14.2386 80 12 77.7614 12 75V28Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48 36C46.7633 36 45.6213 36.6627 45.0077 37.7365L30.4164 63.2712C30.1435 63.7488 30 64.2893 30 64.8394C30 66.5849 31.4151 68 33.1606 68H62.8394C64.5849 68 66 66.5849 66 64.8394C66 64.2893 65.8565 63.7488 65.5836 63.2712L50.9923 37.7365C50.3787 36.6627 49.2367 36 48 36ZM48 44C49.1046 44 50 44.8954 50 46V56C50 57.1046 49.1046 58 48 58C46.8954 58 46 57.1046 46 56V46C46 44.8954 46.8954 44 48 44ZM50 62C50 63.1046 49.1046 64 48 64C46.8954 64 46 63.1046 46 62C46 60.8954 46.8954 60 48 60C49.1046 60 50 60.8954 50 62Z"
          fill="#F9A21F"
        />
        <path
          d="M12 21C12 18.2386 14.2386 16 17 16H79C81.7614 16 84 18.2386 84 21V28H12V21Z"
          fill="#F9A21F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 24C19.1046 24 20 23.1046 20 22C20 20.8954 19.1046 20 18 20C16.8954 20 16 20.8954 16 22C16 23.1046 16.8954 24 18 24ZM26 24C27.1046 24 28 23.1046 28 22C28 20.8954 27.1046 20 26 20C24.8954 20 24 20.8954 24 22C24 23.1046 24.8954 24 26 24ZM36 22C36 23.1046 35.1046 24 34 24C32.8954 24 32 23.1046 32 22C32 20.8954 32.8954 20 34 20C35.1046 20 36 20.8954 36 22Z"
          fill="white"
        />
      </svg>

      <svg
        v-else-if="appearance === 'success'"
        width="97"
        height="97"
        viewBox="0 0 97 97"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2969 28.3086H84.2969V75.3086C84.2969 78.07 82.0583 80.3086 79.2969 80.3086H17.2969C14.5354 80.3086 12.2969 78.07 12.2969 75.3086V28.3086Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.2969 70.3086C57.1334 70.3086 64.2969 63.1451 64.2969 54.3086C64.2969 45.472 57.1334 38.3086 48.2969 38.3086C39.4603 38.3086 32.2969 45.472 32.2969 54.3086C32.2969 63.1451 39.4603 70.3086 48.2969 70.3086ZM57.7159 50.718C58.4943 49.9342 58.49 48.6679 57.7062 47.8895C56.9225 47.1112 55.6562 47.1155 54.8778 47.8992L45.349 57.4936L40.6927 52.9562C39.9016 52.1853 38.6354 52.2017 37.8645 52.9928C37.0936 53.7839 37.11 55.0501 37.9011 55.821L43.9762 61.741C44.762 62.5067 46.0179 62.4964 46.791 61.718L57.7159 50.718Z"
          fill="#48CBAD"
        />
        <path
          d="M12.2969 21.3086C12.2969 18.5472 14.5355 16.3086 17.2969 16.3086H79.2969C82.0583 16.3086 84.2969 18.5472 84.2969 21.3086V28.3086H12.2969V21.3086Z"
          fill="#48CBAD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.2969 24.3086C19.4014 24.3086 20.2969 23.4132 20.2969 22.3086C20.2969 21.204 19.4014 20.3086 18.2969 20.3086C17.1923 20.3086 16.2969 21.204 16.2969 22.3086C16.2969 23.4132 17.1923 24.3086 18.2969 24.3086ZM26.2969 24.3086C27.4014 24.3086 28.2969 23.4132 28.2969 22.3086C28.2969 21.204 27.4014 20.3086 26.2969 20.3086C25.1923 20.3086 24.2969 21.204 24.2969 22.3086C24.2969 23.4132 25.1923 24.3086 26.2969 24.3086ZM36.2969 22.3086C36.2969 23.4132 35.4014 24.3086 34.2969 24.3086C33.1923 24.3086 32.2969 23.4132 32.2969 22.3086C32.2969 21.204 33.1923 20.3086 34.2969 20.3086C35.4014 20.3086 36.2969 21.204 36.2969 22.3086Z"
          fill="white"
        />
      </svg>

      <svg
        v-else-if="appearance === 'error'"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 28H84V75C84 77.7614 81.7614 80 79 80H17C14.2386 80 12 77.7614 12 75V28Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48 70C56.8366 70 64 62.8366 64 54C64 45.1634 56.8366 38 48 38C39.1634 38 32 45.1634 32 54C32 62.8366 39.1634 70 48 70ZM39 52C37.8954 52 37 52.8954 37 54C37 55.1046 37.8954 56 39 56L57 56C58.1046 56 59 55.1046 59 54C59 52.8954 58.1046 52 57 52L39 52Z"
          fill="#FF7E75"
        />
        <path
          opacity="0.6"
          d="M12 21C12 18.2386 14.2386 16 17 16H79C81.7614 16 84 18.2386 84 21V28H12V21Z"
          fill="#D34C09"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 24C19.1046 24 20 23.1046 20 22C20 20.8954 19.1046 20 18 20C16.8954 20 16 20.8954 16 22C16 23.1046 16.8954 24 18 24ZM26 24C27.1046 24 28 23.1046 28 22C28 20.8954 27.1046 20 26 20C24.8954 20 24 20.8954 24 22C24 23.1046 24.8954 24 26 24ZM36 22C36 23.1046 35.1046 24 34 24C32.8954 24 32 23.1046 32 22C32 20.8954 32.8954 20 34 20C35.1046 20 36 20.8954 36 22Z"
          fill="white"
        />
      </svg>
    </div>

    <div class="decorated-result-modal__content">
      <h2 class="decorated-result-modal__title" v-text="title" />

      <slot />

      <ButtonGroup appearance="inline" class="decorated-result-modal__footer">
        <slot name="actions" />
      </ButtonGroup>
    </div>
  </ModalWindow>
</template>
<script>
import { bem } from 'src/utils/bem.js';

import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';

export default {
  name: 'DecoratedResultModal',
  components: {
    ModalWindow,
    ButtonGroup,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    appearance: {
      type: String,
      required: true,
      validator(value) {
        return ['success', 'warning', 'error'].includes(value);
      },
    },
  },
  emits: ['close'],
  methods: {
    bem,

    closeModal(event) {
      this.$emit('close', event);

      document.body.style.overflow = 'auto scroll';
    },
  },
};
</script>
