<template>
  <a :href="href" class="edit-button">
    <span class="mq-not-mobile" v-text="title" />
    <SvgIcon
      class="mq-mobile-only"
      color="muted"
      :icon="mobileIcon"
      :size="iconSize"
      :title="title"
    />
  </a>
</template>

<script>
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'EditButton',
  components: { SvgIcon },
  props: {
    title: {
      type: String,
      default: 'Изменить',
    },
    href: {
      type: String,
      required: true,
    },
    mobileIcon: {
      type: String,
      default: 'pen',
    },
    iconSize: {
      type: Number,
      default: 25,
    },
  },
};
</script>
