<template>
  <div class="company-list-item">
    <div class="company-list-item__avatar">
      <BasicAvatar
        :href="company.href"
        :image="company.avatar"
        size="medium"
        roundness="small"
      />
    </div>
    <div class="company-list-item__main">
      <div class="company-list-item__title">
        <BasicLink
          :href="company.href"
          :title="company.title"
          appearance="dark-bold"
        />
      </div>
      <div class="company-list-item__subtitle">
        <BasicText color="muted">
          {{ company.subtitle }}
        </BasicText>
      </div>
    </div>
  </div>
</template>

<script>
  import BasicAvatar from 'src/components/BasicAvatar/BasicAvatar.vue';
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  import BasicText from 'src/components/text/BasicText/BasicText.vue';
  
  export default {
    name: 'CompanyListItem',
    components: { BasicLink, BasicText, BasicAvatar },
    props: {
      company: {
        type: Object,
        required: true,
      }
    },
  }
</script>