<template>
  <div class="section-group" :class="bem('section-group', { gap })">
    <slot />
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  const GAPS = [
    'smallest',     // 4px
    'small',        // 8px
    'medium',       // 12px
    'medium-large', // 16px
    'large',        // 20px
    'larger',       // 28px
    'largest',      // 40px
  ];

  export default {
    name: 'SectionGroup',
    props: {
      gap: {
        type: String,
        default: 'medium-large',
        validator: v => GAPS.includes(v),
      },
    },
    methods: {
      bem,
    },
  }
</script>