<template>
  <div class="style-ugc" v-html="ugc" />
</template>

<script>
  export default {
    name: 'StyleUgc',
    props: {
      ugc: {
        type: String,
        required: true,
      }
    }
  }
</script>