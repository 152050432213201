<template>
  <div
    class="dropdown"
    :class="bem('dropdown', { fullWidth, minWidth })"
    ref="root"
    data-dropdown-open="open"
    :style="{ width: typeof width === 'number' ? `${width}px` : width }"
  >
    <OuterClick @click="onClick" capture>
      <slot />
    </OuterClick>
  </div>
</template>

<script>
  import { createPopper } from '@popperjs/core';

  import { bem } from 'src/utils/bem.js';

  import OuterClick from 'src/components/OuterClick/OuterClick.vue';

  export default {
    name: 'BasicDropdown',
    components: { OuterClick },
    inject: {
      dropdown: {
        default: null,
      },
    },
    props: {
      target: {
        validator(value) {
          return value instanceof HTMLElement;
        },
      },
      options: Object,
      placement: {
        type: String,
        default: 'bottom-start'
      },
      offset: {
        type: Array,
        default: () => [0, 10]
      },
      fullWidth: Boolean,
      minWidth: String,
      width: [String, Number],
      respectTarget: Boolean,
    },
    data() {
      return {
        popper: null,
      }
    },
    mounted() {
      document.addEventListener('keyup', this.onKeyup, true);

      this.$nextTick(() => {
        let { target } = this;
        if (!target) {
          target = this.dropdown.target;
        }

        const { placement, offset } = this;

        this.popper = createPopper(target, this.$refs.root, {
          placement,
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // causes mobile overflow when set to true
              },
            },
            {
              name: 'offset',
              options: {
                offset,
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 8,
              },
            },
          ],
        });
      });
    },
    destroyed() {
      document.removeEventListener('keyup', this.onKeyup, true);
    },
    methods: {
      bem,
      onKeyup(event) {
        if (event.code === 'Escape') {
          this.$emit('close', event);
          this.dropdown.hide(event);
        }
      },
      onClick(event) {
        if (
          this.respectTarget &&
          this.dropdown &&
          (
            this.dropdown.target.contains(event.target) ||
            event.target === this.dropdown.target
          )
        ) {
          return;
        }
        this.$emit('close', event);
        this.dropdown.hide(event);
      },
    },
  }
</script>