<template>
  <div class="submit-box" :class="bem('submit-box', { appearance })">
    <slot />
  </div>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'SubmitBox',
    props: {
      appearance: String,
    },
    methods: {
      bem,
    }
  }
</script>