<template>
  <DropdownTarget
    v-if="user.print || user.report || user.admin"
    v-slot="{ visible, show, hide }"
  >
    <BasicButton @click="show" appearance="context" size="m">
      <SvgIcon size="24" icon="dots" />
    </BasicButton>
    <TransitionFade>
      <BasicDropdown v-if="visible" @close="hide">
        <BasicForm
          v-if="showArsenalButton"
          :action="action"
          :params="params"
          @success="onSuccess"
        >
          <template #default="{ requestInProcess }">
            <BasicButton
              v-if="user.arsenal.href"
              :href="user.arsenal.href"
              target="_blank"
              appearance="dropdown-list-item"
            >
              Открыть в Арсенале
            </BasicButton>
            <BasicButton
              v-else
              :disabled="requestInProcess"
              type="submit"
              appearance="dropdown-list-item"
            >
              Добавить в Арсенал
            </BasicButton>
          </template>
        </BasicForm>

        <template v-if="user.print">
          <BasicButton :href="user.print.html" appearance="dropdown-list-item">
            Распечатать резюме
          </BasicButton>
          <BasicButton :href="user.print.pdf" appearance="dropdown-list-item">
            Скачать резюме в PDF
          </BasicButton>
          <BasicButton :href="user.print.doc" appearance="dropdown-list-item">
            Скачать резюме в DOC
          </BasicButton>
        </template>
        <BasicButton
          v-if="user.report"
          appearance="dropdown-list-item"
          @click="
            $emit('report');
            hide();
          "
        >
          Пожаловаться
        </BasicButton>
        <BasicButton
          v-if="user.admin"
          :href="user.admin.href"
          target="_blank"
          appearance="dropdown-list-item"
        >
          Профиль в админке
        </BasicButton>
        <BasicButton
          v-if="user.companyRatingsAdminHref"
          :href="user.companyRatingsAdminHref"
          target="_blank"
          appearance="dropdown-list-item"
        >
          Отзывы о компаниях
        </BasicButton>
        <BasicButton
          v-if="user.courseRatingsAdminHref"
          :href="user.courseRatingsAdminHref"
          target="_blank"
          appearance="dropdown-list-item"
        >
          Отзывы о курсах
        </BasicButton>
      </BasicDropdown>
    </TransitionFade>
  </DropdownTarget>
</template>

<script>
import { notify } from 'src/notify.js';
import { ArsenalCandidatesResource } from 'src/services/arsenal.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicDropdown from 'src/components/BasicDropdown/BasicDropdown.vue';
import DropdownTarget from 'src/components/DropdownTarget/DropdownTarget.vue';
import BasicForm from 'src/components/form/BasicForm/BasicForm.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

export default {
  name: 'UserSidebarContextMenu',
  components: {
    TransitionFade,
    DropdownTarget,
    BasicButton,
    BasicDropdown,
    SvgIcon,
    BasicForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    visitor: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  emits: ['report', 'update:user'],
  methods: {
    action: ArsenalCandidatesResource.addUser,
    onSuccess({ arsenal, error }) {
      if (error) {
        notify({ message: error, type: 'error' });
      } else {
        this.$emit('update:user', {
          ...this.user,
          arsenal,
        });
      }
    },
  },
  computed: {
    showArsenalButton() {
      return this.visitor?.company && this.user?.arsenal;
    },
    params() {
      return {
        login: this.user.id,
        companyId: this.visitor.company.id,
      };
    },
  },
};
</script>
