<template>
  <ul
    class="separated-list"
    :class="bem('separated-list', { gap })"
  >
    <li
      v-for="item in items"
      :key="getKey ? getKey(item) : null"
      class="separated-list__item"
    >
      <slot :item="item" />
    </li>
  </ul>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'SeparatedList',
    props: {
      items: {
        type: Array,
        required: true,
      },
      getKey: Function,
      gap: {
        type: String,
        default: 'medium'
      },
    },
    methods: {
      bem,
    },
  }
</script>