<template>
  <div class="sidebar-section">
    <SectionBox>
      <BasicSection appearance="sidebar-section">
        <div class="sidebar-section-block">
          <div class="sidebar-section__head">
            <span class="sidebar-section__head-title">{{ title }}</span>
            <slot name="afterTitle" />
          </div>

          <div
            class="sidebar-section__body"
            :class="bem('sidebar-section__body', { appearance })">
            <slot name="body" />

            <div
              v-if="moreLinkHref && moreLinkTitle"
              class="sidebar-section__body-controls"
            >
              <BasicLink
                :href="moreLinkHref"
                :title="moreLinkTitle"
                appearance="more"
              />
            </div>
          </div>
        </div>
      </BasicSection>
    </SectionBox>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import BasicLink from 'src/components/BasicLink/BasicLink.vue';
import SectionBox from 'src/components/SectionBox/SectionBox.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';

export default {
  name: 'SidebarSection',
  components: {
    BasicLink,
    BasicSection,
    SectionBox,
  },
  props: {
    appearance: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    moreLinkHref: {
      type: String,
    },
    moreLinkTitle: {
      type: String,
    },
  },
  methods: {
    bem,
  },
}
</script>