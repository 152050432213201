<template>
  <div
    class="transition-expand"
    ref="container"
    :style="style"
  >
    <transition
      name="transition-fade"
      
      @before-enter="beforeEnter"
      @before-leave="overflow = 'hidden'"
      @enter="enter"
      @leave="leave"

      @after-enter="overflow = 'visible';clearHeight($event)"
      @after-leave="clearHeight"

      :duration="duration"
    >
      <slot />
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'TransitionExpand',
    props: {
      duration: {
        type: [String, Number],
        default: 200
      },
    },
    data() {
      return {
        height: null,
        overflow: 'visible',
      }
    },
    methods: {
      enter() {
        const { container } = this.$refs;
        const startHeight = this.height;
        
        // do not let current height affect final height
        this.height = null;
        
        // wait for height to sync
        this.$nextTick(() => {
          // container height will respect margin collapsing of inserted element
          const endHeight = container.clientHeight;
          this.height = startHeight;
          // eslint-disable-next-line no-void
          void container.offsetHeight // force reflow
  
          // wait for microtasks to finish
          requestAnimationFrame(() => {
            this.height = endHeight;
          });
        });
      },
      leave(el) {
        const { container } = this.$refs;
  
        // toggle between single and cross-transition
        if (container.children.length === 1) {
          this.height = container.clientHeight;
          // eslint-disable-next-line no-void
          void container.offsetHeight // force reflow
  
          // wait for microtasks to finish
          requestAnimationFrame(() => {
            this.height = 0;
          });
        }
        
        el.classList.add('transition-expand__detached');
      },
      beforeEnter() {
        // sync on beforeEnter so element is not yet inserted, i.e. doesn't affect container height
        const { container } = this.$refs;
        this.height = container.clientHeight;
      },
      clearHeight() {
        this.height = null;
      },
    },
    computed: {
      style() {
        const { duration, height, overflow } = this;
        return {
          '--expand-duration': `${duration}ms`,
          height: height !== null ? `${height}px` : null,
          overflow,
        }
      },
    }
  }
</script>
