import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

/**
 * @typedef {"vacancy" | "resumes_card" | "profile" } InvitedFromValue
 */

/**
 * @typedef {{
 *  userLogin: string,
 *  invitedFrom: InvitedFromValue,
 *  message?: string,
 *  vacancyId?: number
 * }} SendInviteData
 */

export const JobInvitesService = {
  /**
   * @param {SendInviteData} data
   */
  sendInvite(data) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/job_invites`,
      data,
    });
  },
  /**
   * @param {string} userLogin
   */
  trackPlaceholderView(userLogin) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/job_invites/track_placeholder_view`,
      data: { userLogin },
    });
  },
};
