<template>
  <div
    class="text-length"
    :class="bem('text-length', { exceeded: current >= maxLength })"
  >
    {{ current }} / {{ maxLength }}
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'TextLength',
    props: {
      maxLength: {
        type: Number,
        required: true,
      },
      value: {
        type: String,
        default: '',
      },
    },
    methods: {
      bem,
    },
    computed: {
      current() {
        return this.value && this.value.length || 0;
      },
    },
  };
</script>
