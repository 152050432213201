var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"job-experience-item"},[_c('WithAvatarAside',{attrs:{"href":_vm.company.href,"image":_vm.company.image}},[_c('div',{staticClass:"job-experience-item__header",class:_vm.bem('job-experience-item__header', {
          centered: _vm.shouldCenterTitle,
          rate: Boolean(_vm.company.rateHref),
        })},[_c('BasicText',{attrs:{"weight":"bold","size":"large"}},[(_vm.company.href)?_c('BasicLink',{attrs:{"href":_vm.company.href,"title":_vm.company.title,"appearance":"dark"}}):_c('BasicText',{attrs:{"color":"muted"}},[_vm._v(" "+_vm._s(_vm.company.title)+" ")])],1),_c('CompanyIcons',{attrs:{"company":_vm.company}}),(_vm.company.rating)?_c('div',{staticClass:"job-experience-item__rating"},[_c('CompanyRating',{attrs:{"rating":_vm.company.rating}})],1):_vm._e(),(_vm.company.rateHref)?_c('div',{staticClass:"job-experience-item__rate"},[_c('CompanyScoreLink',{attrs:{"rate-href":_vm.company.rateHref,"ga-label":"user_experience_main"}})],1):_vm._e()],1),(_vm.company.subtitle)?_c('div',{staticClass:"job-experience-item__subtitle",domProps:{"textContent":_vm._s(_vm.company.subtitle)}}):_vm._e(),(_vm.meta.length !== 0)?_c('div',{staticClass:"job-experience-item__meta"},[_c('InlineList',{attrs:{"items":_vm.meta},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [(item.href)?_c('BasicLink',{attrs:{"href":item.href,"title":item.title,"appearance":"dark"}}):_c('span',{domProps:{"textContent":_vm._s(item.title)}})]}}],null,false,44925241)})],1):_vm._e(),(_vm.showSingleTimelineItem)?_c('div',{staticClass:"job-experience-item__short-info"},[_c('JobPosition',{attrs:{"position":_vm.company.positions[0]}})],1):_vm._e()]),(_vm.company.positions && !_vm.showSingleTimelineItem)?_c('div',{staticClass:"job-experience-item__positions"},[_c('ConnectedTimeline',{attrs:{"items":_vm.company.positions,"get-key":function (position) { return position.title; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('JobPosition',{attrs:{"position":item}})]}}],null,false,291575362)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }