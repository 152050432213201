<template>
  <DropdownTarget
    ref="target"
    full-width
    v-slot="{ visible, hide }"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <DropdownLabel
      :label="label"
      :placeholder="placeholder"
      @click="manageDropdown(visible)"
    >
      <template #rest>
        <slot name="label-rest" />
      </template>
      <template #aside>
        <slot name="label-aside" />
      </template>
    </DropdownLabel>
    <TransitionFade>
      <BasicDropdown
        v-if="visible"
        full-width
        respect-target
        @close="model = ''"
      >
        <div class="search-in-dropdown__input">
          <TextInput
            ref="input"
            v-model="model"
            appearance="dropdown-search"
            :placeholder="searchPlaceholder"
            :autocomplete="autocomplete"
            :invalid="invalid"
            @keyup.esc="hide"
            @focus="$emit('focus', $event)"
            v-on="listeners"
          >
            <template #icon>
              <SvgIcon icon="search" size="24" />
            </template>
          </TextInput>
        </div>
        <slot />
      </BasicDropdown>
    </TransitionFade>
  </DropdownTarget>
</template>

<script>

  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  import BasicDropdown from 'src/components/BasicDropdown/BasicDropdown.vue';
  import DropdownLabel from 'src/components/DropdownLabel/DropdownLabel.vue';
  import DropdownTarget from 'src/components/DropdownTarget/DropdownTarget.vue';
  import TextInput from 'src/components/form/TextInput/TextInput.vue';
  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

  export default {
    name: 'SearchInDropdown',
    components: { SvgIcon, DropdownLabel, TransitionFade, DropdownTarget, BasicDropdown, TextInput },
    model: modelValueConfig(),
    props: {
      modelValue: String,
      placeholder: String,
      autocomplete: String,
      label: String,
      searchPlaceholder: {
        type: String,
        default: 'Поиск',
      },
      invalid: Boolean,
    },
    emits: ['show', 'hide', 'focus'],
    methods: {
      manageDropdown(visible) {
        if (visible) {
          this.$refs.target.hide();
        } else {
          this.$refs.target.show();
          this.focusInput();
        }
      },
      async focusInput() {
        // Если подождать один раз, то страница скроллится вниз,
        // так как инпут не успевает спозиционироваться
        await this.$nextTick();
        await this.$nextTick();

        this.$refs.input.focus();
      }
    },
    computed: {
      model: createPropModel('modelValue'),
      listeners() {
        const { click, focus, 'update:modelValue': _, ...listeners } = this.$listeners;
        return listeners;
      },
    },
  }
</script>
