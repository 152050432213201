<template>
  <div class="button-group" :class="bem('button-group', { appearance })">
    <slot />
  </div>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'ButtonGroup',
    props: {
      appearance: String,
    },
    methods: {
      bem,
    }
  }
</script>