var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user.print || _vm.user.report || _vm.user.admin)?_c('DropdownTarget',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var visible = ref.visible;
var show = ref.show;
var hide = ref.hide;
return [_c('BasicButton',{attrs:{"appearance":"context","size":"m"},on:{"click":show}},[_c('SvgIcon',{attrs:{"size":"24","icon":"dots"}})],1),_c('TransitionFade',[(visible)?_c('BasicDropdown',{on:{"close":hide}},[(_vm.showArsenalButton)?_c('BasicForm',{attrs:{"action":_vm.action,"params":_vm.params},on:{"success":_vm.onSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var requestInProcess = ref.requestInProcess;
return [(_vm.user.arsenal.href)?_c('BasicButton',{attrs:{"href":_vm.user.arsenal.href,"target":"_blank","appearance":"dropdown-list-item"}},[_vm._v(" Открыть в Арсенале ")]):_c('BasicButton',{attrs:{"disabled":requestInProcess,"type":"submit","appearance":"dropdown-list-item"}},[_vm._v(" Добавить в Арсенал ")])]}}],null,true)}):_vm._e(),(_vm.user.print)?[_c('BasicButton',{attrs:{"href":_vm.user.print.html,"appearance":"dropdown-list-item"}},[_vm._v(" Распечатать резюме ")]),_c('BasicButton',{attrs:{"href":_vm.user.print.pdf,"appearance":"dropdown-list-item"}},[_vm._v(" Скачать резюме в PDF ")]),_c('BasicButton',{attrs:{"href":_vm.user.print.doc,"appearance":"dropdown-list-item"}},[_vm._v(" Скачать резюме в DOC ")])]:_vm._e(),(_vm.user.report)?_c('BasicButton',{attrs:{"appearance":"dropdown-list-item"},on:{"click":function($event){_vm.$emit('report');
          hide();}}},[_vm._v(" Пожаловаться ")]):_vm._e(),(_vm.user.admin)?_c('BasicButton',{attrs:{"href":_vm.user.admin.href,"target":"_blank","appearance":"dropdown-list-item"}},[_vm._v(" Профиль в админке ")]):_vm._e(),(_vm.user.companyRatingsAdminHref)?_c('BasicButton',{attrs:{"href":_vm.user.companyRatingsAdminHref,"target":"_blank","appearance":"dropdown-list-item"}},[_vm._v(" Отзывы о компаниях ")]):_vm._e(),(_vm.user.courseRatingsAdminHref)?_c('BasicButton',{attrs:{"href":_vm.user.courseRatingsAdminHref,"target":"_blank","appearance":"dropdown-list-item"}},[_vm._v(" Отзывы о курсах ")]):_vm._e()],2):_vm._e()],1)]}}],null,false,2294267690)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }