<template>
  <span class="arrow-icon" :class="bem('arrow-icon', { direction })">
    <SvgIcon :icon="type ? `arrow-${type}` : 'arrow'" :size="size" :color="color" />
  </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

  export default {
    name: 'ArrowIcon',
    components: { SvgIcon },
    props: {
      direction: String,
      size: String,
      color: String,
      type: String,
    },
    methods: {
      bem,
    }
  }
</script>