const fixRurCurrency = (currency) => currency.toLowerCase() === 'rur' ? 'rub' : currency;

export const formatCurrency = (value, currency) => {
  const useGrouping = value >= 10000;

  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: fixRurCurrency(currency),
    maximumFractionDigits: 0,
    currencyDisplay: 'symbol',
    useGrouping,
  }).format(value);
};
