<template>
  <SectionGroup gap="smallest">
    <div
      v-for="request in requests"
      :key="request.title"
    >
      <BasicText color="muted">{{ request.title }}: </BasicText>
      <InlineList
        :items="request.items"
        :get-key="getKey"
        v-slot="{ item }"
      >
        <span v-if="noLink">{{ item.title }}</span>
        <InlineListLink
          v-else
          :item="item"
        />
      </InlineList>
    </div>
  </SectionGroup>
</template>

<script>
  import InlineList from 'src/components/InlineList/InlineList.vue';
  import InlineListLink from 'src/components/InlineListLink/InlineListLink.vue';
  import SectionGroup from 'src/components/sections/SectionGroup/SectionGroup.vue';
  import BasicText from 'src/components/text/BasicText/BasicText.vue';

  export default {
    name: 'ExpertRequestsList',
    components: { InlineListLink, InlineList, BasicText, SectionGroup },
    props: {
      requests: {
        type: Array,
        required: true,
      },
      noLink: Boolean,
    },
    methods: {
      getKey(item) {
        return item.title
      }
    }
  };
</script>
