<template>
  <ContentSection
    :title="title"
    appearance="expert-profile"
  >
    <InlineList
      :items="list"
      :get-key="getKey"
      v-slot="{ item }"
    >
      <InlineListLink :item="item" />
    </InlineList>
  </ContentSection>
</template>

<script>
  import InlineList from '../../InlineList/InlineList.vue';
  import InlineListLink from '../../InlineListLink/InlineListLink.vue';
  import ContentSection from '../../sections/ContentSection/ContentSection.vue';

  export default {
    name: 'ExpertLinksSection',
    components: { InlineListLink, InlineList, ContentSection },
    props: {
      list: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        default: null,
      }
    },
    methods: {
      getKey(item) {
        return item.title;
      },
    },
  };
</script>
