<template>
  <div class="input-label">
    <label class="input-label__label" :for="forId" v-text="text" />
    <slot />
  </div>
</template>
<script>
export default {
  name: 'InputLabel',
  props: {
    text: {
      type: String,
      required: true,
    },
    forId: {
      type: String,
      required: true,
    },
  },
};
</script>
