<template>
  <div class="with-spinner-placeholder" :class="bem('with-spinner-placeholder', { fullHeight })">
    <div
      class="with-spinner-placeholder__content"
      :class="bem('with-spinner-placeholder__content', { hidden: loading })"
    >
      <slot />
    </div>
    <transition name="transition-fade">
      <div v-if="loading" class="with-spinner-placeholder__overlay">
        <SvgIcon icon="spinner" :size="size" :color="color" />
      </div>
    </transition>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

  export default {
    name: 'WithSpinnerPlaceholder',
    components: { SvgIcon },
    props: {
      loading: Boolean,
      fullHeight: Boolean,
      size: {
        type: Number,
        default: 20
      },
      color: String,
    },
    methods: {
      bem,
    },
  }
</script>