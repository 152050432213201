<template>
  <ModalWindow
    title="Приглашение на&nbsp;собеседование"
    appearance="new"
    @close="$emit('close', $event)"
  >
    <BasicForm
      class="job-invite-form-modal__form"
      :action="formAction"
      v-slot="{ requestInProcess }"
      @success="emitSuccessEvents"
    >
      <FormField v-if="jobInviteButton.vacancies.length" name="vacancyId">
        <InputLabel text="Вакансия" for-id="job_invite_vacancy">
          <SuggestionsPicker
            placeholder="Выберите вакансию"
            v-model="vacancyId"
            :action="getCuratorVacancies"
            :models.sync="vacancies"
            search-placeholder="Выберите вакансию"
            :display-selected="false"
            :is-clear-result="false"
            :default-query="
              jobInviteButton.vacancy ? jobInviteButton.vacancy.title : null
            "
            preloaded
          />
        </InputLabel>
      </FormField>

      <FormField name="message" v-slot="{ invalid }">
        <InputLabel text="Текст" for-id="job_invite_message">
          <BasicTextarea
            name="message"
            id="job_invite_message"
            :max-length="255"
            v-model="message"
            :invalid="invalid"
            placeholder="Сообщение для пользователя"
            :rows="5"
            :max-rows="5"
          />
        </InputLabel>
      </FormField>

      <div v-if="jobInviteButton.showHint" class="job-invite-form-modal__hint">
        При отправке приглашения спишется открытие контакта
      </div>

      <ButtonGroup appearance="inline" class="job-invite-form-modal__footer">
        <BasicButton
          :disabled="submitIsDisabled || requestInProcess"
          type="submit"
          appearance="primary"
          size="l"
        >
          Отправить
        </BasicButton>
        <BasicButton
          appearance="secondary"
          size="l"
          @click="$emit('close', $event)"
        >
          Отменить
        </BasicButton>
      </ButtonGroup>
    </BasicForm>
  </ModalWindow>
</template>
<script>
import { JobInvitesService } from 'src/services/job-invites.js';
import { CareerSuggestionsResource } from 'src/services/suggestions.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import BasicForm from 'src/components/form/BasicForm/BasicForm.vue';
import BasicTextarea from 'src/components/form/BasicTextarea/BasicTextarea.vue';
import FormField from 'src/components/form/FormField/FormField.vue';
import InputLabel from 'src/components/form/InputLabel/InputLabel.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import SuggestionsPicker from 'src/components/suggestions/SuggestionsPicker/SuggestionsPicker.vue';

export default {
  name: 'JobInviteFormModal',
  components: {
    ModalWindow,
    BasicForm,
    BasicButton,
    BasicTextarea,
    InputLabel,
    ButtonGroup,
    SuggestionsPicker,
    FormField,
  },
  inject: {
    replaceDefaultJobInviteParams: {
      default: null,
    },
  },
  props: {
    jobInviteButton: {
      type: Object,
      required: true,
    },
    invitedFrom: {
      type: String,
      required: true,
      validator(value) {
        return ['vacancy', 'resumes_card', 'profile'].includes(value);
      },
    },
  },
  emits: ['success', 'close'],
  data() {
    return {
      vacancyId: this.jobInviteButton.vacancy?.value ?? null,
      message: this.jobInviteButton.message ?? '',

      vacancies: this.jobInviteButton.vacancies,
    };
  },
  methods: {
    async formAction() {
      const { userLogin } = this.jobInviteButton;
      const { vacancyId, message, invitedFrom } = this;

      window.helpers.trackEventByName('invite_send');

      return JobInvitesService.sendInvite({
        userLogin,
        vacancyId,
        message,
        invitedFrom,
      });
    },
    getCuratorVacancies: CareerSuggestionsResource.getCuratorVacancies,
    emitSuccessEvents(response) {
      this.$emit('success', response);

      const vacancy =
        this.jobInviteButton.vacancies.find(
          (v) => v.value === this.vacancyId,
        ) ?? null;

      if (typeof this.replaceDefaultJobInviteParams === 'function') {
        this.replaceDefaultJobInviteParams({
          vacancy,
          message: this.message,
        });
      }
    },
  },
  computed: {
    submitIsDisabled() {
      return !this.message && !this.vacancyId;
    },
  },
};
</script>
