<template>
  <div class="resume-rating-item">
    <WithAvatarAside>
      <template #avatar>
        <SocialRatingIcon :href="rating.href" :icon="rating.icon" />
      </template>
      <template #default>
        <BasicText size="large" weight="bold">
          <BasicLink
            :href="rating.href"
            :title="rating.title"
            appearance="dark"
          />
        </BasicText>
        <div class="resume-rating-item__meta">
          Участвует <span v-text="rating.duration" />
        </div>
        <div
          class="resume-rating-item__contribution"
          v-text="rating.contribution"
        />
        <div class="resume-rating-item__topics">
          <InlineList :items="rating.topics" v-slot="{ item }">
            <RankedItem :item="item" />
          </InlineList>
        </div>
        <div class="resume-rating-item__link">
          <BasicLink
            title="Перейти в профиль"
            appearance="blue-bold"
            :href="rating.user.href"
            @click.native="sendAnalytics()"
          />
          <BasicTooltip
            v-if="visitor && visitor.isGuest"
            content="Зарегистрируйтесь, чтобы переходить по ссылкам в профсообщества"
            placement="top"
            :max-width="258"
            theme="dark"
          >
            <span class="icon icon-question" />
          </BasicTooltip>
        </div>
      </template>
    </WithAvatarAside>
  </div>
</template>

<script>
import BasicLink from 'src/components/BasicLink/BasicLink.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import InlineList from 'src/components/InlineList/InlineList.vue';
import RankedItem from 'src/components/RankedItem/RankedItem.vue';
import SocialRatingIcon from 'src/components/SocialRatingIcon/SocialRatingIcon.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import WithAvatarAside from 'src/components/WithAvatarAside/WithAvatarAside.vue';

export default {
  name: 'ResumeRatingItem',
  components: {
    BasicTooltip,
    RankedItem,
    BasicText,
    InlineList,
    BasicLink,
    SocialRatingIcon,
    WithAvatarAside,
  },
  inject: ['visitor'],
  props: {
    rating: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sendAnalytics() {
      const eventName = `contacts_comm_${this.rating.icon}`;

      window.helpers.trackEventByName(eventName, {
        event_category: 'profile',
      });
    },
  },
};
</script>
