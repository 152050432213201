/**
 * @param {any[]} arr
 * @return {(value: any) => boolean}
 */
export const variants = arr => value => arr.includes(value);

/**
 * @param {any[]} arr
 * @return {(value: any) => boolean}
 */
export const optionalVariants = arr => value => (value ? variants(arr)(value) : true);
