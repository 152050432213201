<template>
  <div
    class="section-box"
    :class="bem('section-box', { shadow, fullHeight, appearance })"
  >
    <slot />
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'SectionBox',
    props: {
      shadow: String,
      fullHeight: Boolean,
      appearance: String,
    },
    methods: {
      bem,
    }
  }
</script>