<template>
  <BasicLink :href="item.href" appearance="dark" v-bind="$attrs">
    <InlineAlign gap="small">
      <template v-if="withRank && item.rank" #start>
        <BasicTooltip :content="item.rank.title" placement="top">
          <SvgIcon icon="star" size="12" :color="item.rank.level" />
        </BasicTooltip>
      </template>
      <template #default>
        <HighlightedItem v-if="item.highlighted">
          <span v-text="item.title" />
        </HighlightedItem>
        <span v-else v-text="item.title" />
      </template>
    </InlineAlign>
  </BasicLink>
</template>

<script>
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
  import HighlightedItem from 'src/components/HighlightedItem/HighlightedItem.vue';
  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
  import InlineAlign from 'src/components/InlineAlign/InlineAlign.vue';

  export default {
    name: 'RankedItem',
    components: { HighlightedItem, SvgIcon, BasicTooltip, InlineAlign, BasicLink },
    props: {
      item: {
        type: Object,
        required: true,
      },
      withRank: {
        type: Boolean,
        default: true,
      },
    }
  }
</script>