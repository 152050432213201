<template>
  <BasicButton
    data-label="user_experience_main"
    :href="rateHref"
    appearance="rate"
    size="m"
    @click.native="trackCompanyRatingView(gaLabel)"
  >
    Оценить
  </BasicButton>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';

import { trackCompanyRatingView } from 'src/parts/company_score';

export default {
  name: 'CompanyScoreLink',
  components: {
    BasicButton,
  },
  props: {
    rateHref: {
      type: String,
      required: true,
    },
    gaLabel: {
      type: String,
    }
  },
  methods: {
    trackCompanyRatingView,
  }
}
</script>