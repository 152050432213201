<template>
  <component
    :is="hasTooltip ? 'BasicTooltip' : 'span'"
    content="Провожу экспертные консультации"
    class="expert-icon"
  >
    <SvgIcon icon="expert" size="24" />
  </component>
</template>

<script>
  import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

  export default {
    name: 'ExpertIcon',
    components: { BasicTooltip, SvgIcon },
    props: {
      hasTooltip: {
        type: Boolean,
        default: true,
      },
    }
  };
</script>
