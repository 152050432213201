<template>
  <component
    :is="href ? 'a' : 'span'"
    class="basic-avatar"
    :href="href"
    :target="target"
    :class="bem('basic-avatar', { size, roundness })"
  >
    <img
      :src="image.src"
      :alt="image.alt"
      class="basic-avatar__image"
      :title="image.title"
    >
  </component>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'BasicAvatar',
    props: {
      image: {
        type: Object,
        required: true,
      },
      href: String,
      roundness: String,
      size: {
        type: String,
        default: 'medium'
      },
      target: String,
    },
    methods: {
      bem,
    }
  }
</script>
