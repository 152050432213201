<template>
  <PageSection>
    <WithSidebar placement="left" collapse="top">
      <template #sidebar>
        <UserPageSidebar
          :user="user"
          :is-expert="user.isExpert"
          @openContacts="$emit('openContacts')"
        />
      </template>
      <template #default>
        <slot />
      </template>
    </WithSidebar>
  </PageSection>
</template>

<script>
import PageSection from 'src/components/sections/PageSection/PageSection.vue';
import UserPageSidebar from 'src/components/users/UserPageSidebar/UserPageSidebar.vue';
import WithSidebar from 'src/components/WithSidebar/WithSidebar.vue';

export default {
  name: 'UserPageTemplate',
  components: {
    UserPageSidebar,
    WithSidebar,
    PageSection,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
