<template>
  <div v-if="shouldShowBanner" class="skill-banner">
    <a
      ref="skillBanner"
      class="skill-banner__link"
      target="_blank"
      :href="quizLink"
    >
      <div class="skill-banner__title">Оцените свой&nbsp;навык SQL</div>
      <div class="skill-banner__description">
        Пройдите тест и получите отметку в профиле, чтобы стать заметнее среди
        других специалистов.
      </div>
      <div class="skill-banner__school">
        <img
          class="skill-banner__school-image"
          src="~images/YP_avatar.png"
          width="24"
          height="24"
          alt=""
        />
        <div class="skill-banner__school-info">
          <div class="skill-banner__school-name">Яндекс Практикум</div>
          <div class="skill-banner__school-label">Автор теста</div>
        </div>
      </div>
      <img
        class="skill-banner__label"
        src="~images/skills_banner_label.png"
        width="148"
        height="100"
        alt=""
      />
      <img
        class="skill-banner__image"
        src="~images/skills_banner_bg.png"
        alt=""
      />
    </a>
    <button
      v-if="canClose"
      title="Закрыть баннер"
      class="skill-banner__close"
      type="button"
      @click="closeBanner"
    >
      <SvgIcon icon="cross" size="12" color="muted" />
    </button>
  </div>
</template>

<script>
import { SkillTestResource } from 'src/services/skill-test.js';

import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'SkillBanner',
  components: { SvgIcon },
  props: {
    quizId: {
      type: Number,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shouldShowBanner: true,
    };
  },
  mounted() {
    window.helpers.trackEvent('banner_skill', 'impr', 'Yandex_SQL', true);

    const link = this.$refs.skillBanner;
    link.addEventListener('click', () => {
      window.helpers.trackEvent('banner_skill', 'click', 'Yandex_SQL', true);
    });
  },
  methods: {
    closeBanner() {
      this.shouldShowBanner = false;
      SkillTestResource.closeSkillBanner(this.quizId);
      window.helpers.trackEvent('banner_skill', 'close', 'Yandex_SQL', true);
    },
  },
  computed: {
    quizLink() {
      return `/quiz/${this.quizId}`;
    },
  },
};
</script>
