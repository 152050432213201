<template>
  <div class="job-position">
    <div class="job-position__title">
      <BasicText weight="bold">
        <InlineList :items="title" v-slot="{ item }">{{ item }}</InlineList>
      </BasicText>
    </div>
    <div
      v-if="position.duration"
      class="job-position__duration"
      v-text="position.duration"
    />
    <div
      v-if="position.message"
      class="job-position__message"
    >
      <StyleUgc :ugc="position.message" />
    </div>
    <div
      v-if="position.tags"
      class="job-position__tags"
    >
      <InlineList
        :items="position.tags"
        :get-key="(tag) => tag.title"
        v-slot="{ item }"
      >
        <BasicLink
          :title="item.title"
          :href="item.href"
          appearance="dark"
        />
      </InlineList>
    </div>
  </div>
</template>

<script>
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  import InlineList from 'src/components/InlineList/InlineList.vue';
  import StyleUgc from 'src/components/StyleUgc/StyleUgc.vue';
  import BasicText from 'src/components/text/BasicText/BasicText.vue';

  export default {
    name: 'JobPosition',
    components: { StyleUgc, BasicLink, InlineList, BasicText },
    props: {
      position: {
        type: Object,
        required: true,
      }
    },
    computed: {
      title() {
        let specAndQualification = this.position.specialization || '';
        if (this.position.qualification) {
          specAndQualification += ` (${this.position.qualification})`;
        }

        return [
          specAndQualification,
          this.position.title,
        ].filter(Boolean);
      },
    }
  }
</script>