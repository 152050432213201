import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const SkillTestResource = {
  getSkillResult({ id, login }) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/skill_tests/${id}/result?login=${login}`,
    });
  },
  closeSkillBanner(id) {
    return cancellableRequest({
      method: 'patch',
      url: `${apiUrlV1}/skill_tests/${id}/close_banner`,
    });
  },
}