<template>
  <span
    class="dropdown-label"
    :class="bem('dropdown-label', { disabled })"
    tabindex="0"
    :style="{ width }"
    @click="$emit('click', $event)"
    @keyup.enter="$emit('click', $event)"
  >
    <span class="dropdown-label__label" v-text="label || placeholder" />
    <span class="dropdown-label__rest">
      <slot name="rest"></slot>
    </span>
    <span class="dropdown-label__aside">
      <slot name="aside"></slot>
    </span>
  </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'DropdownLabel',
    props: {
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: '',
      },
      width: {
        type: String,
        default: 'auto',
      },
      disabled: {
        type: Boolean,
        default: false,
      }
    },
    emits: ['click'],
    methods: {
      bem,
    }
  };
</script>
