<template>
  <BasicLink
    v-if="item.href"
    :href="item.href"
    :title="item.title"
    appearance="dark"
  />
  <span
    v-else
    v-text="item.title"
  />
</template>

<script>
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';

  export default {
    name: 'InlineListLink',
    components: { BasicLink },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
  };
</script>
