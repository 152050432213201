<template>
  <div class="expert-score-form">
    <TransitionFade mode="out-in">
      <div
        v-if="showMessage"
        key="message"
        class="expert-score-form__message"
        :class="bem('expert-score-form__message', { column })"
      >
        <template v-if="cancelled">
          Жаль, что встреча не состоялась.<br>Спасибо за обратную связь!
        </template>
        <template v-else>
          Спасибо за оценку!<br>Она будет доступна публично после того, как эксперт отметит, что встреча прошла.
        </template>
      </div>
      <div
        v-else
        key="form"
        class="expert-score-form__form"
        :class="bem('expert-score-form__form', { column })"
      >
        <RatingStarsForm
          :disabled="isLoading"
          @vote="vote"
        />
        <BasicButton
          appearance="secondary"
          size="m"
          :disabled="isLoading"
          @click="cancel"
        >
          Встреча не состоялась
        </BasicButton>
      </div>
    </TransitionFade>
  </div>
</template>

<script>

  import { handleRequestError } from 'src/services/api.js';
  import { ExpertsService } from 'src/services/experts.js';
  import { bem } from 'src/utils/bem.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import RatingStarsForm from 'src/components/RatingStarsForm/RatingStarsForm.vue';
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

  export default {
    name: 'ExpertScoreForm',
    components: { RatingStarsForm, TransitionFade, BasicButton },
    props: {
      alias: {
        type: String,
        required: true,
      },
      column: Boolean,
    },
    emits: ['action'],
    data() {
      return {
        cancelled: false,
        showMessage: false,
        isLoading: false,
      }
    },
    methods: {
      vote(score) {
        this.$emit('action', 'stars');
        this.runAction(
          ExpertsService.setExpertScore,
          { alias: this.alias, score },
        );
      },
      cancel() {
        this.$emit('action', 'canceled');
        this.runAction(
          ExpertsService.cancelByUser,
          { alias: this.alias },
        )
          .then(() => {
            this.cancelled = true;
          });
      },
      runAction(action, params) {
        this.isLoading = true;

        return action(params)
          .then(() => {
            this.showMessage = true;
          })
          .catch(handleRequestError)
          .finally(() => {
            this.isLoading = false;
          });
      },
      bem,
    },
  };
</script>
