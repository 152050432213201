<template>
  <div class="input-group" :class="bem('input-group', { direction, gap, appearance })">
    <slot />
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'InputGroup',
    props: {
      direction: String,
      gap: String,
      appearance: String,
    },
    methods: {
      bem,
    },
  }
</script>