import { delegateEvent } from 'src/utils/dom.js';

export function trackCompanyRatingView(label) {
  window.helpers.trackEvent('company_rating_form', 'page_view', label)
}

export function initCompanyScoreLinksTracking() {
  delegateEvent(document, 'click', '.js-company-score-link', (event, target) => {
    const {label} = target.dataset;

    if (!label) return;

    trackCompanyRatingView(label);
  })

  delegateEvent(document, 'click', '.top_notice a', (event, target) => {
    const {href} = target;

    if (!href) return;

    const {pathname} = new URL(href)

    if (pathname !== '/profile/company_ratings/new') return;

    trackCompanyRatingView('banner');
  })
}

document.addEventListener('DOMContentLoaded', initCompanyScoreLinksTracking);