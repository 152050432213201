<template>
  <BasicButton appearance="remove" v-bind="$attrs" v-on="$listeners">
    <SvgIcon icon="cross" size="10" />
  </BasicButton>
</template>

<script>
  import BasicButton from "src/components/BasicButton/BasicButton.vue";
  import SvgIcon from "src/components/icons/SvgIcon/SvgIcon.vue";

  export default {
    name: 'RemoveButton',
    components: { SvgIcon, BasicButton }
  }
</script>