<template>
  <div class="with-avatar-aside">
    <aside class="with-avatar-aside__aside">
      <slot name="avatar">
        <BasicAvatar
          :href="href"
          :image="image"
          :roundness="roundness"
          :size="size"
        />
      </slot>
    </aside>
    <div class="with-avatar-aside__content">
      <slot />
    </div>
  </div>
</template>

<script>
  import BasicAvatar from "src/components/BasicAvatar/BasicAvatar.vue";

  export default {
    name: 'WithAvatarAside',
    components: { BasicAvatar },
    props: {
      href: String,
      image: {
        type: Object,
      },
      roundness: {
        type: String,
        default: 'small',
      },
      size: {
        type: String,
        default: 'medium'
      },
    }
  }
</script>