<template>
  <span
    class="dropdown-target"
    :class="bem('dropdown-target', { fullWidth })"
    ref="dropdownTarget"
  >
    <slot :show="show" :hide="dropdown.hide" :visible="visible" />
  </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'DropdownTarget',
    props: {
      fullWidth: Boolean,
      disabled: Boolean,
    },
    emits: ['show', 'hide'],
    provide() {
      const { dropdown } = this;
      return {
        dropdown
      }
    },
    data() {
      return {
        dropdown: {
          target: null,
          hide: (event) => {
            this.visible = false;
            if (event) {
              // eslint-disable-next-line no-param-reassign
              event.closeDropdown = this.dropdown.target;
            }
            this.$emit('hide');
          },
        },
        visible: false,
      }
    },
    mounted() {
      this.dropdown.target = this.$refs.dropdownTarget;
    },
    methods: {
      bem,
      show(event) {
        if (event && event.closeDropdown === this.dropdown.target) return;
        this.visible = true;
        this.$emit('show');
      },
      hide(event) {
        this.dropdown.hide(event);
        this.$emit('hide');
      },
    },
  }
</script>