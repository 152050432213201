<template>
  <div
    class="basic-notification"
    :class="bem('basic-notification', { appearance })"
  >
    <button
      v-if="closable"
      class="basic-notification__close"
      @click="$emit('close')"
    >
      <SvgIcon icon="cross" :size="12" />
    </button>
    <div class="basic-notification__container">
      <SvgIcon class="basic-notification__icon" :icon="icon" :size="24" />
      <div class="basic-notification__content">
        <div v-if="$slots.title" class="basic-notification__title">
          <slot name="title" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';
import { optionalVariants } from 'src/utils/validators.js';

import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'BasicNotification',
  components: { SvgIcon },
  props: {
    appearance: {
      type: String,
      default: 'info',
      validator: optionalVariants([
        'info',
        'alert',
        'alert-borderless',
        'news',
        'attention',
        'alarm',
        'alarm-borderless',
      ]),
    },
    icon: {
      type: String,
      default: 'info',
    },
    closable: Boolean,
  },
  emits: ['close'],
  methods: {
    bem,
  },
};
</script>
