<template>
  <div class="recommendation-letters">
    <SeparatedList
      :items="letters"
      v-slot="{ item }"
    >
      <RecommendationLetterItem
        :letter="item"
        :approve-fallback-href="approveFallbackHref"
      />
    </SeparatedList>
  </div>
</template>

<script>
  import RecommendationLetterItem from "src/components/resume/RecommendationLetterItem/RecommendationLetterItem.vue";
  import SeparatedList from "src/components/SeparatedList/SeparatedList.vue";

  export default {
    name: 'RecommendationLetters',
    components: { RecommendationLetterItem, SeparatedList },
    props: {
      letters: {
        type: Array,
        required: true,
      },
      approveFallbackHref: String,
    }
  }
</script>