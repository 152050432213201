<template>
  <UserPageTemplate :user="currentUser" @openContacts="openContacts">
    <UserResume
      v-if="resume"
      :resume="resume"
      :user="currentUser"
      :has-active-connection="hasActiveConnection"
      :show-expert-score-form="visibleConnectionFeedback"
      :skills-banner="skillsBanner"
      @openContacts="openContacts"
    />
    <SectionBox v-else>
      <BasicSection>
        <UserPagePlaceholder :user="currentUser" :placeholder="placeholder" />
      </BasicSection>
    </SectionBox>
  </UserPageTemplate>
</template>

<script>
import { showErrorNotification } from 'src/services/api.js';
import { PersonalResource } from 'src/services/personal.js';

import SectionBox from 'src/components/SectionBox/SectionBox.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
import UserPagePlaceholder from 'src/components/users/UserPagePlaceholder/UserPagePlaceholder.vue';
import UserPageTemplate from 'src/components/users/UserPageTemplate/UserPageTemplate.vue';
import UserResume from 'src/components/users/UserResume/UserResume.vue';

export default {
  name: 'UserPage',
  components: {
    UserPageTemplate,
    BasicSection,
    SectionBox,
    UserPagePlaceholder,
    UserResume,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    resume: Object,
    placeholder: Object,
    hasActiveConnection: Boolean,
    visibleConnectionFeedback: Boolean,
    skillsBanner: Object,
  },
  provide() {
    return {
      contactsConditions: this.user.contacts
        ? this.user.contacts.conditions
        : null,
      getContactsIsLoading: () => this.contactsIsLoading,
    };
  },
  data() {
    return {
      currentUser: this.user,
      contactsIsLoading: false,
    };
  },
  methods: {
    async openContacts() {
      this.contactsIsLoading = true;
      try {
        const response = await PersonalResource.getUserContacts(this.user.id);
        this.currentUser.contacts = response.data.contacts;
        if (this.currentUser.jobInviteButton?.showHint) {
          this.currentUser.jobInviteButton.showHint = false;
        }
      } catch (error) {
        showErrorNotification(error);
      } finally {
        this.contactsIsLoading = false;
      }
    },
  },
};
</script>
