<template>
  <div class="profile-percentage-diagram">
    <svg class="profile-percentage-diagram__svg" viewBox="0 0 80 80">
      <foreignObject
        width="80"
        height="80"
        class="profile-percentage-diagram__foreign"
        x="0"
        y="0"
      >
        <div
          class="profile-percentage-diagram__gradient"
          xmlns="http://www.w3.org/1999/xhtml"
        ></div>
      </foreignObject>

      <g class="profile-percentage-diagram__blend">
        <rect class="profile-percentage-diagram__back" x="0" y="0" />
        <circle
          :style="`stroke-dashoffset: ${strokeDashoffset}`"
          ref="circle"
          class="profile-percentage-diagram__circle"
          r="36"
          cx="40"
          cy="40"
        />
      </g>
    </svg>
    <div class="profile-percentage-diagram__value">{{ value }}%</div>
  </div>
</template>

<script>
const dashoffsetMax = 136;
const dashoffsetMin = 360;
const dashoffsetDelta = dashoffsetMin - dashoffsetMax;

export default {
  name: 'PercentageDiagram',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      strokeDashoffset: dashoffsetMin,
    };
  },
  mounted() {
    this.setStrokeDashoffset();
  },
  methods: {
    setStrokeDashoffset() {
      const strokeDashoffset =
        dashoffsetMax + ((100 - this.value) / 100) * dashoffsetDelta;
      this.strokeDashoffset = strokeDashoffset;
    },
  },
};
</script>
