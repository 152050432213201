<template>
  <div class="page-title" :class="bem('page-title', { justifyContent })">
    <h1 class="page-title__title" v-text="title" />
    <div class="page-title__aside">
      <slot />
    </div>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'PageTitle',
    props: {
      title: {
        type: String,
        required: true,
      },
      justifyContent: {
        type: String,
        default: 'space-between',
        validator(value) {
          return ['space-between', 'start'].includes(value)
        }
      }
    },
    methods: {
      bem,
    }
  }
</script>
