<template>
  <div
    class="content-section"
    :class="bem('content-section', { appearance, noMargin })"
  >
    <div
      class="content-section__header"
      :class="bem('content-section__header', { appearance, 'disabled' : isDisabled })"
    >
      <component
        v-if="title || $slots.title"
        :is="titleTag"
        class="content-section__title"
        :class="bem('content-section__title', { appearance, 'required' : isRequired, 'disabled' : isDisabled })"
      >
        <slot v-if="$slots.title" name="title" />
        <template v-else-if="href">
          <BasicLink
            :href="href"
            :title="title"
            underline="small"
          />
        </template>
        <template v-else>
          {{title}}
        </template>
      </component>
      <div v-if="$slots.aside" class="content-section__aside">
        <slot name="aside" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import BasicLink from 'src/components/BasicLink/BasicLink.vue';

  export default {
    name: 'ContentSection',
    components: { BasicLink },
    props: {
      title: String,
      href: String,
      appearance: String,
      titleTag: {
        type: String,
        default: 'h2',
      },
      noMargin: Boolean,
      isRequired: Boolean,
      isDisabled: Boolean,
    },
    methods: {
      bem,
    }
  }
</script>