<template>
  <div class="user-profile-progress">
    <p class="user-profile-progress__description">
      Это ваша визитка в ИТ-мире. Мы подскажем, что заполнить в первую очередь,
      чтобы стать заметнее на профессиональном рынке.
    </p>

    <div class="user-profile-progress__body">
      <div class="user-profile-progress__recommends">
        <BasicText
          class="user-profile-progress__recommends-title"
          weight="bold"
          size="medium"
        >
          <span>{{ progressTitle }}</span>
        </BasicText>

        <div class="user-profile-progress__recommends-info">
          <PercentageDiagram :value="progressData.progress" />
          <RecommendsSlider :recommendations="progressData.recommendations" />
        </div>
      </div>
      <ProfileComparison
        :progress="progressData.progress"
        :description="comparisonDescription"
        :graph-items="progressData.progressGraphItems"
        :avatar-src="avatarSrc"
      />
    </div>
  </div>
</template>

<script>
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import PercentageDiagram from 'src/components/users/UserProfileProgress/components/PercentageDiagram.vue';
import ProfileComparison from 'src/components/users/UserProfileProgress/components/ProfileComparison.vue';
import RecommendsSlider from 'src/components/users/UserProfileProgress/components/RecommendsSlider.vue';

export default {
  name: 'UserProfileProgress',
  components: {
    BasicText,
    ProfileComparison,
    PercentageDiagram,
    RecommendsSlider,
  },
  props: {
    progressData: {
      type: Object,
      required: true,
    },
    avatarSrc: {
      type: String,
      required: true,
    },
  },
  computed: {
    comparisonDescription() {
      return `Мы знакомы с вами лучше, чем с ${this.progressData.rating}% пользователей`;
    },
    progressTitle() {
      const { progress } = this.progressData;

      if (progress <= 30) return 'Давайте знакомиться';

      if (progress <= 70) return 'Станьте заметнее';

      if (progress <= 99) return 'Еще чуть-чуть и 100%';

      return 'Готово, вы восхитительны';
    },
  },
};
</script>
