export const pick = (object, path) => {
  return path.split('.').reduce((obj, value) => {
    return obj[value];
  }, object);
};

export const isFalsyObject = (object) => {
  return Object.keys(object).some(key => {
    const value = object[key];
    if (Array.isArray(value)) return value.length > 0;
    return Boolean(value);
  });
};

export const mapObjectKeys = (source, keyMap) => {
  const res = {};

  Object.keys(source).forEach(key => {
    const value = source[key];
    const newKey = keyMap[key] || key;
    res[newKey] = value;
  });

  return res;
};

export const filterEmptyValues = (object) => {
  const res = {};

  Object.keys(object).forEach(key => {
    const value = object[key];
    if (isEmptyValue(value)) return;
    res[key] = value;
  });

  return res;
};

const isEmptyValue = value => {
  if (Array.isArray(value) && value.length === 0) return true;
  return (value === "" || value === null || value === undefined || value === false);
};

export const createObjectMapper = ({ filterEmpty = false, map = {}, defaults = {}, remove = [], }) => {
  return (object = {}) => {
    let res = {};

    for (let key in object) {
      const value = object[key];

      if (key in defaults && defaults[key] === value) continue;

      if (remove.includes(key)) continue;

      if (filterEmpty && isEmptyValue(value)) continue;

      if (key in map) {
        key = map[key];
      }

      res[key] = value;
    }

    return res;
  };
}