<template>
  <div class="resume-education-item">
    <WithAvatarAside
      :href="education.href"
      :image="education.image"
    >
      <h3
        class="resume-education-item__title"
        :class="bem('resume-education-item__title', { centered: shouldCenterTitle })"
      >
        <BasicText weight="bold" size="large">
          <LinkOrText :item="education" />
        </BasicText>
      </h3>
      <div
        v-if="education.subtitle"
        class="resume-education-item__subtitle"
        v-text="education.subtitle"
      />
      <div
        v-if="meta.length !== 0"
        class="resume-education-item__meta"
      >
        <InlineList
          :items="meta"
          v-slot="{ item }"
        >
          <LinkOrText :item="item" />
        </InlineList>
      </div>
      <div
        v-if="showSingleTimelineItem"
        class="resume-education-item__short-info"
      >
        <EducationCourse :course="education.courses[0]" />
      </div>
      
    </WithAvatarAside>
    <div
      v-if="!showSingleTimelineItem"
      class="resume-education-item__courses"
    >
      <ConnectedTimeline
        :items="education.courses"
        :get-key="(faculty) => faculty.id"
        v-slot="{ item }"
      >
        <EducationCourse :course="item" />
      </ConnectedTimeline>
    </div>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  import ConnectedTimeline from 'src/components/ConnectedTimeline/ConnectedTimeline.vue';
  import InlineList from 'src/components/InlineList/InlineList.vue';
  import LinkOrText from 'src/components/LinkOrText/LinkOrText.vue';
  import EducationCourse from 'src/components/resume/EducationCourse/EducationCourse.vue';
  import BasicText from 'src/components/text/BasicText/BasicText.vue';
  import WithAvatarAside from 'src/components/WithAvatarAside/WithAvatarAside.vue';

  export default {
    name: 'ResumeEducationItem',
    components: { LinkOrText, WithAvatarAside, EducationCourse, ConnectedTimeline, InlineList, BasicText },
    props: {
      education: {
        type: Object,
        required: true,
      }
    },
    methods: {
      bem,
    },
    computed: {
      meta() {
        const { education } = this;
        const result = [];

        if (education.location) {
          result.push({
            title: education.location,
          });
        }

        if (education.users) {
          result.push({
            title: education.users.title,
            href: education.users.href,
          });
        }

        return result;
      },
      showSingleTimelineItem() {
        return !this.hasDescription && this.education.courses.length === 1;
      },
      shouldCenterTitle() {
        return !this.hasDescription && this.education.courses.length > 1;
      },
      hasDescription() {
        return this.education.subtitle || this.meta.length;
      }
    },
  }
</script>