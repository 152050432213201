import { reactive } from 'vue';

import { getSsrState } from 'src/utils/ssr.js';
import { createVueApp } from 'src/utils/vue.js';

import UserPage from 'src/components/users/UserPage/UserPage.vue';
import VisitorProvider from 'src/components/users/VisitorProvider/VisitorProvider.vue';

const container = document.querySelector('[data-root]');

if (container) {
  const {
    user: initialUserData,
    resume,
    placeholder,
    visitor,
    hasActiveConnection,
    visibleConnectionFeedback,
    skillsBanner,
  } = getSsrState();

  const user = reactive(initialUserData);

  const setJobInviteState = (response, state = 'already_sent') => {
    if (!user.jobInviteButton) return;

    user.jobInviteButton.state = state;

    if (response && response.contacts) {
      user.contacts = response.contacts;
    }
  };

  const app = createVueApp({
    provide() {
      return { setJobInviteState };
    },
    render(h) {
      return h(
        VisitorProvider,
        {
          props: {
            visitor,
          },
        },
        [
          h(UserPage, {
            props: {
              user,
              resume,
              placeholder,
              hasActiveConnection,
              visibleConnectionFeedback,
              skillsBanner,
            },
          }),
        ],
      );
    },
  });

  app.$mount(container);
}
