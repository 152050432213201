<template>
  <span>{{ formatCurrency(amount, currency) }}</span>
</template>

<script>
  import { formatCurrency } from '../../utils/currency.js';

  export default {
    name: 'InlineCurrency',
    props: {
      amount: {
        type: Number,
        default: 0,
      },
      currency: {
        type: String,
        default: 'RUB',
      },
    },
    methods: {
      formatCurrency,
    },
  };
</script>
