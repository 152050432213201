<template>
  <section class="user-contacts" @click="checkEmailsAndCopy">
    <h3 class="user-contacts__title">
      Контакты
      <EditButton v-if="contacts.edit" :href="contacts.edit" />
    </h3>

    <WithSpinnerPlaceholder
      v-if="contacts.items.length"
      ref="placeholder"
      :loading="getContactsIsLoading()"
    >
      <ul class="user-contacts__list">
        <UserContactsItem
          v-for="(contact, idx) in contacts.items"
          @openContacts="openContacts"
          :contact="contact"
          :is-show-contacts="contacts.contactsAvailable"
          :key="contact.kind + idx"
        />
      </ul>
    </WithSpinnerPlaceholder>
    <BasicButton
      v-if="conditionsAction"
      :href="conditionsAction.type === 'link' ? conditionsAction.href : ''"
      :appearance="conditionsAction.appearance"
      size="m"
      rel="nofollow"
      @click="openContacts(conditionsAction)"
    >
      {{ conditionsAction.title }}
    </BasicButton>

    <BasicText v-if="contacts.hint" color="muted" size="small">
      <LinkOrText :item="contacts.hint" appearance="dimmed" />
    </BasicText>

    <p v-if="contacts.showNotice" class="user-contacts__notice">
      <a
        ref="conversationLink"
        :href="`/conversations/${userLogin}`"
        @click="trackConversationLinkClick"
        >Свяжитесь со специалистом</a
      >
      через диалог на Хабр Карьере — тогда вероятность, что он ответит на ваше
      сообщение, увеличится в два раза.
    </p>
  </section>
</template>

<script>
import { notify } from 'src/notify.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import EditButton from 'src/components/buttons/EditButton/EditButton.vue';
import LinkOrText from 'src/components/LinkOrText/LinkOrText.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import UserContactsItem from 'src/components/users/UserContactsItem/UserContactsItem.vue';

export default {
  name: 'UserContacts',
  components: {
    WithSpinnerPlaceholder,
    LinkOrText,
    BasicText,
    BasicButton,
    EditButton,
    UserContactsItem,
  },
  inject: ['getContactsIsLoading'],
  props: {
    contacts: {
      type: Object,
      required: true,
      validator(value) {
        return Array.isArray(value.items);
      },
    },
    visitor: {
      type: Object,
      default: null,
    },
    userLogin: {
      type: String,
      required: true,
    },
    curatorLogin: {
      type: String,
    },
  },
  emits: ['openContacts'],
  mounted() {
    if (this.$refs.placeholder) {
      // фиксируем высоту, чтобы лоадинг не менял размер элемента
      this.$refs.placeholder.$el.style.height = `${this.$refs.placeholder.$el.clientHeight}px`;
    }
  },
  methods: {
    trackConversationLinkClick() {
      window.helpers.trackEvent('contacts', 'dialog');
    },
    async checkEmailsAndCopy(event) {
      const href = `${event.target?.getAttribute('href')}`;

      if (!href.startsWith('mailto:')) return;
      const email = href.replace('mailto:', '');
      event.preventDefault();

      try {
        await navigator.clipboard.writeText(email);
        notify({ message: 'Адрес скопирован' });
      } catch (err) {
        notify({
          message: 'Не удалось скопировать email-адрес',
          type: 'error',
        });
      }
    },
    openContacts(action) {
      if (action && action.type === 'link') return;
      this.$emit('openContacts');
    },
  },
  computed: {
    showDialogsNotice() {
      if (this.visitor?.company) return true;

      return this.contacts.items.length === 0;
    },
    conditionsAction() {
      if (this.contacts.items.length === 0) return null;

      return this.contacts.conditions?.action;
    },
  },
};
</script>
