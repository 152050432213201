<template>
  <div
    class="connected-timeline"
    :class="bem('connected-timeline', { gap, jointAlign })"
  >
    <div
      v-for="(item, index) in items"
      :key="getKey ? getKey(item) : null"
      class="connected-timeline__item"
    >
      <div class="connected-timeline__aside">
        <template v-if="items.length !== 1">
          <div class="connected-timeline__circle" />
          <div
            v-if="index !== items.length - 1"
            class="connected-timeline__line"
          />
        </template>
      </div>
      <div class="connected-timeline__content">
        <slot :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'ConnectedTimeline',
    props: {
      items: {
        type: Array,
        required: true,
      },
      gap: {
        type: String,
        default: 'medium'
      },
      getKey: Function,
      jointAlign: {
        type: String,
        default: 'text'
      },
    },
    methods: {
      bem,
    },
  }
</script>