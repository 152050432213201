<template>
  <div class="recommendation-letter-item">
    <WithAvatarAside
      :href="letter.user.href"
      :image="letter.user.avatar"
    >
      <BasicText weight="bold" size="large">
        <BasicLink
          :title="letter.user.title"
          :href="letter.user.href"
          appearance="dark"
        />
      </BasicText>
      <div
        v-if="meta.length !== 0"
        class="recommendation-letter-item__meta"
      >
        <BasicText color="muted">
          <InlineList
            :items="meta"
            v-slot="{ item }"
          >
            <span v-text="item" />
          </InlineList>
        </BasicText>
      </div>
      <div
        class="recommendation-letter-item__message"
        v-html="letter.message"
      />
      <div
        v-if="letter.createdAt"
        class="recommendation-letter-item__date"
      >
        <BasicText color="muted">
          <span v-text="letter.createdAt.title" />
        </BasicText>
      </div>
      <div
        v-if="letter.approve || letter.canDelete"
        class="recommendation-letter-item__footer"
      >
        <div
          v-if="letter.approve"
          class="recommendation-letter-item__approve"
        >
          <ApproveButton
            v-model="letter.approve"
            :user-id="letter.user.id"
            :letter-id="letter.id"
            :fallback="approveFallbackHref"
            @update:modelValue="() => {$forceUpdate()}"
          />
        </div>
        <div
          v-if="letter.canDelete"
          class="recommendation-letter-item__delete"
        >
          <ResourceAction
            ref="deleteActionRef"
            :action="deleteAction"
            :params="deleteParams"
            @success="onDeleteSuccess"
          >
            <BasicButton
              appearance="secondary"
              size="m"
              @click="confirmVisible = true"
            >
              Удалить
            </BasicButton>
          </ResourceAction>
          <TransitionFade>
            <BasicConfirm
              v-if="confirmVisible"
              title="Удаление письма"
              @close="confirmVisible = false"
              @confirm="$refs.deleteActionRef.dispatch()"
            >
              Вы уверены что хотите удалить ваше рекомендательное письмо?
            </BasicConfirm>
          </TransitionFade>
        </div>
      </div>
    </WithAvatarAside>
  </div>
</template>

<script>
  import { notify } from 'src/notify.js';
  import { PersonalResource } from 'src/services/personal.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import BasicConfirm from 'src/components/BasicConfirm/BasicConfirm.vue';
  import BasicLink from "src/components/BasicLink/BasicLink.vue";
  import ApproveButton from 'src/components/buttons/ApproveButton/ApproveButton.vue';
  import InlineList from "src/components/InlineList/InlineList.vue";
  import ResourceAction from 'src/components/ResourceAction/ResourceAction.vue';
  import BasicText from "src/components/text/BasicText/BasicText.vue";
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';
  import WithAvatarAside from "src/components/WithAvatarAside/WithAvatarAside.vue";

  export default {
    name: 'RecommendationLetterItem',
    components: { BasicConfirm, TransitionFade, ResourceAction, BasicButton, ApproveButton, InlineList, BasicLink, BasicText, WithAvatarAside },
    props: {
      letter: {
        type: Object,
        required: true,
      },
      approveFallbackHref: String,
    },
    data() {
      return {
        confirmVisible: false,
      }
    },
    methods: {
      deleteAction: PersonalResource.deleteRecommendationLetter,
      onDeleteSuccess({ data: { redirectTo, error } }) {
        if (error) {
          const { message } = error;
          notify({ message, type: 'error' });
        } else {
          window.location.replace(redirectTo);
        }
      },
    },
    computed: {
      meta() {
        const { letter } = this;
        const result = [];

        if (letter.user.position) {
          result.push(letter.user.position);
        }

        if (letter.user.relation) {
          result.push(letter.user.relation);
        }

        return result;
      },
      deleteParams() {
        const { id: letterId } = this.letter;
        return { letterId };
      },
    }
  }
</script>