<template>
  <div class="page-section" :class="bem('page-section', { font })">
    <slot />
  </div>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'PageSection',
    props: {
      font: String,
    },
    methods: {
      bem,
    },
  }
</script>