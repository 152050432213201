<template>
  <div class="user-list-item">
    <div class="user-list-item__main">
      <div class="user-list-item__avatar">
        <BasicAvatar
          :href="user.href"
          :image="user.avatar"
          size="medium"
          roundness="full"
        />
      </div>
      <div class="user-list-item__info">
        <div class="user-list-item__title">
          <BasicLink
            :href="user.href"
            :title="user.title"
            appearance="dark-bold"
            class="user-list-item__link"
          />
          <InlineIconsList v-if="user.isExpert">
            <ExpertIcon />
          </InlineIconsList>
        </div>
        <div class="user-list-item__sub-title">
          <BasicText color="muted">
            {{ user.subtitle }}
          </BasicText>
        </div>
      </div>
    </div>
    <div
      v-if="$slots.aside"
      class="user-list-item__aside"
    >
      <slot name="aside" />
    </div>
  </div>
</template>

<script>
  import BasicAvatar from 'src/components/BasicAvatar/BasicAvatar.vue';
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  import ExpertIcon from 'src/components/experts/ExpertIcon/ExpertIcon.vue';
  import InlineIconsList from 'src/components/InlineIconsList/InlineIconsList.vue';
  import BasicText from 'src/components/text/BasicText/BasicText.vue';
  
  export default {
    name: 'UserListItem',
    components: { ExpertIcon, InlineIconsList, BasicAvatar, BasicText, BasicLink },
    props: {
      user: {
        type: Object,
        required: true,
      }
    }
  }
</script>