<template>
  <ModalWindow
    v-if="!successVisible"
    title="Укажите причину жалобы"
    @close="$emit('close')"
  >
    <BasicForm
      :action="action"
      :params="{ userId }"
      @success="successVisible = true"
    >
      <SectionBox full-height shadow="none">
        <BasicSection>
          <SectionGroup>
            <template v-for="option in options">
              <BasicRadioButton
                v-model="reason"
                :value="option.value"
                name="reason"
              >
                {{ option.title }}
              </BasicRadioButton>
              <div
                v-if="option.items && reason === option.value"
                class="report-modal__reason-items"
              >
                <SectionGroup>
                  <BasicCheckbox
                    v-for="item in option.items"
                    :key="item.value"
                    v-model="reasonItems"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ item.title }}
                  </BasicCheckbox>
                </SectionGroup>
              </div>
            </template>
          </SectionGroup>
        </BasicSection>
        <SubmitBox>
          <BasicSection>
            <BasicButton
              type="submit"
              appearance="primary"
              size="l"
            >
              Отправить жалобу
            </BasicButton>
          </BasicSection>
        </SubmitBox>
      </SectionBox>
      <input
        v-for="item in reasonItems"
        type="hidden"
        :value="item"
        name="reason_items[]"
      >
    </BasicForm>
  </ModalWindow>
  <ModalWindow
    v-else
    @close="$emit('close')"
  >
    <SectionBox full-height shadow="none">
      <BasicSection>
        <NoticeCard
          :image="$options.image"
          title="Жалоба отправлена"
        />
      </BasicSection>
    </SectionBox>
  </ModalWindow>
</template>

<script>
  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import BasicCheckbox from 'src/components/form/BasicCheckbox/BasicCheckbox.vue';
  import BasicForm from 'src/components/form/BasicForm/BasicForm.vue';
  import BasicRadioButton from 'src/components/form/BasicRadioButton/BasicRadioButton.vue';
  import NoticeCard from 'src/components/NoticeCard/NoticeCard.vue';
  import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
  import SectionBox from 'src/components/SectionBox/SectionBox.vue';
  import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
  import SectionGroup from 'src/components/sections/SectionGroup/SectionGroup.vue';
  import SubmitBox from 'src/components/SubmitBox/SubmitBox.vue';

  import image from 'images/vacancy_subscription_success.png';
  import { PersonalResource } from 'src/services/personal.js';
  
  export default {
    name: 'ReportModal',
    components: {
      SectionGroup,
      BasicCheckbox,
      BasicRadioButton,
      NoticeCard,
      BasicForm,
      BasicButton,
      SubmitBox,
      BasicSection,
      SectionBox,
      ModalWindow,
    },
    image: {
      src: image,
      alt: '',
    },
    props: {
      options: {
        type: Array,
        required: true,
      },
      userId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        successVisible: false,
        reason: null,
        reasonItems: [],
      }
    },
    watch: {
      reason(value) {
        const option = this.options.find(option => option.value === value);
        if (!option.items) {
          this.reasonItems = [];
        } else {
          this.reasonItems = option.items.filter(i => i.checked).map(i => i.value);
        }
      },
    },
    methods: {
      action: PersonalResource.reportUser,
    }
  }
</script>