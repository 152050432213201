<template>
  <div class="resume-ratings">
    <SeparatedList
      :items="ratings"
      :get-key="(rating) => rating.type"
      gap="medium"
      v-slot="{ item }"
    >
      <ResumeRatingItem :rating="item" />
    </SeparatedList>
  </div>
</template>

<script>
import ResumeRatingItem from 'src/components/resume/ResumeRatingItem/ResumeRatingItem.vue';
import SeparatedList from 'src/components/SeparatedList/SeparatedList.vue';

export default {
  name: 'ResumeRatings',
  components: { SeparatedList, ResumeRatingItem },
  props: {
    ratings: {
      type: Array,
      required: true,
    },
  },
};
</script>
