<template>
  <BasicOverlay v-show="showModal">
    <div
      class="modal-window"
      :class="bem('modal-window', { popup, withEmbed, appearance })"
    >
      <div
        class="modal-window__scroll-content"
        :class="bem('modal-window__scroll-content', { appearance })"
        @mousedown="onModalClick"
        ref="boxRef"
        :style="{
          transform: `translateY(${topTranslate}px)`,
          opacity,
        }"
      >
        <div
          v-if="appearance === 'new'"
          ref="sausageRef"
          class="modal-window__sausage"
        />

        <div
          class="modal-window__wrapper"
          :class="bem('modal-window__wrapper', { appearance })"
          ref="wrapper"
        >
          <div
            class="modal-window__box"
            :class="bem('modal-window__box', { popup, withEmbed, appearance })"
          >
            <div
              v-if="hasHeader"
              class="modal-window__header"
              :class="
                bem('modal-window__header', { popup, withEmbed, appearance })
              "
            >
              <button
                v-if="$listeners.back"
                class="modal-window__back"
                @click="$emit('back', $event)"
              >
                <ArrowIcon direction="left" size="28" color="dark" />
              </button>
              <div
                v-if="title"
                class="modal-window__title"
                :class="bem('modal-window__title', { appearance })"
                v-html="title"
              />
              <button
                class="modal-window__close"
                type="button"
                :class="
                  bem('modal-window__close', { popup, withEmbed, appearance })
                "
                @click="onClose"
              >
                <SvgIcon
                  icon="cross"
                  size="18"
                  :color="popup ? 'muted-blue' : 'dark'"
                />
              </button>
            </div>
            <div
              class="modal-window__content"
              :class="
                bem('modal-window__content', { popup, withEmbed, appearance })
              "
            >
              <slot />
              <slot name="body" />
            </div>
            <TransitionExpand>
              <div
                v-if="$slots.footer"
                class="modal-window__footer"
                :class="
                  bem('modal-window__footer', { popup, withEmbed, appearance })
                "
              >
                <slot name="footer" />
              </div>
            </TransitionExpand>
          </div>
        </div>
      </div>
    </div>
  </BasicOverlay>
</template>

<script>
import { usePointerSwipe, useWindowSize } from '@vueuse/core';
import { ref } from 'vue';

import { bem } from 'src/utils/bem.js';

import ArrowIcon from 'src/components/icons/ArrowIcon/ArrowIcon.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import BasicOverlay from 'src/components/overlays/BasicOverlay/BasicOverlay.vue';
import TransitionExpand from 'src/components/transitions/TransitionExpand/TransitionExpand.vue';

export default {
  name: 'ModalWindow',
  components: { TransitionExpand, ArrowIcon, SvgIcon, BasicOverlay },
  props: {
    title: String,
    popup: Boolean,
    withEmbed: Boolean,
    hasHeader: {
      type: Boolean,
      default: true,
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    appearance: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'new'].includes(value);
      },
    },
  },
  emits: ['close', 'back'],
  setup(props, { emit }) {
    const sausageRef = ref(null);

    const { height } = useWindowSize();
    const topTranslate = ref(0);
    const opacity = ref(1);

    function reset() {
      topTranslate.value = 0;
      opacity.value = 1;
    }

    function onClose(event) {
      emit('close', event);
      window.history.back();
    }

    const { distanceY } = usePointerSwipe(sausageRef, {
      threshold: 10,
      onSwipe() {
        if (distanceY.value >= 0) {
          topTranslate.value = 0;
          return;
        }

        topTranslate.value = distanceY.value * -1;
      },
      onSwipeEnd(event, direction) {
        if (direction === 'up') {
          reset();
          return;
        }

        if (direction === 'down') {
          if (topTranslate.value > height.value / 4) {
            opacity.value = 0;
            onClose();
            setTimeout(reset, 250);
            return;
          }
          reset();
        }
      },
    });

    return {
      sausageRef,
      reset,
      topTranslate,
      opacity,
      onClose,
    };
  },
  watch: {
    showModal: {
      immediate: true,
      handler(newValue) {
        document.body.style.overflow = newValue ? 'hidden' : 'auto scroll';
      },
    },
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyup);
    window.addEventListener('popstate', this.onPopstate);
    if (this.title === 'Специализация') return;
    window.history.pushState(null, null, window.location.href);
  },
  destroyed() {
    document.removeEventListener('keyup', this.onKeyup);
    window.removeEventListener('popstate', this.onPopstate);
    document.body.style.overflow = 'auto scroll';
  },
  methods: {
    bem,
    onModalClick(event) {
      if (
        event.target !== this.$refs.wrapper &&
        event.target.contains(this.$refs.wrapper)
      ) {
        this.$emit('close', event);
        window.history.back();
      }
    },
    onKeyup(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.$emit('close', event);
        window.history.back();
      }
    },
    onPopstate(event) {
      if (!event) return;
      if (this.$listeners.back) {
        window.history.pushState(null, null, window.location.href);
        this.$emit('back', event);
      } else {
        this.$emit('close', event);
      }
    },
  },
};
</script>
