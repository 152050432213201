<template>
  <a
    class="social-rating-icon"
    :class="bem('social-rating-icon', { type: icon })"
    :href="href"
  >
    <SvgIcon
      source="/images/social-ratings.svg"
      :icon="`social-rating-${icon}`"
      :size="size"
    />
  </a>
</template>

<script>
  import SvgIcon from "src/components/icons/SvgIcon/SvgIcon.vue";
  import { bem } from "src/utils/bem.js";

  const sizes = {
    habr: 50,
    toster: 50,
    dribbble: 32,
    behance: 32,
    geektimes: 32,
  }

  export default {
    name: 'SocialRatingIcon',
    components: { SvgIcon },
    props: {
      href: {
        type: String,
        required: true,
      },
      icon: String,
    },
    methods: {
      bem,
    },
    computed: {
      size() {
        return sizes[this.icon] || 50;
      }
    }
  }
</script>