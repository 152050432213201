<template>
  <DropdownTarget
    ref="target"
    full-width
    v-slot="{ visible, hide, show }"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <TextInput
      v-model="model"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :invalid="invalid"
      :disabled="disabled"
      @keyup.enter="show"
      @keyup.esc="hide"
      @update:modelValue="show"
      @focus="$emit('focus', $event); hide($event)"
      @blur="$emit('blur')"
      @click="show"
      v-on="listeners"
    />
    <TransitionFade>
      <BasicDropdown
        v-if="visible && $slots.default"
        full-width
        respect-target
      >
        <slot />
      </BasicDropdown>
    </TransitionFade>
  </DropdownTarget>
</template>

<script>
  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  import BasicDropdown from 'src/components/BasicDropdown/BasicDropdown.vue';
  import DropdownTarget from 'src/components/DropdownTarget/DropdownTarget.vue';
  import TextInput from 'src/components/form/TextInput/TextInput.vue';
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

  export default {
    name: 'SearchWithDropdown',
    components: { TransitionFade, DropdownTarget, BasicDropdown, TextInput },
    model: modelValueConfig(),
    props: {
      modelValue: String,
      placeholder: String,
      autocomplete: String,
      disabled: Boolean,
      invalid: Boolean,
    },
    emits: ['show', 'hide', 'focus', 'blur'],
    methods: {
      hideDropdown() {
        this.$refs.target.hide();
      },
      showDropdown() {
        this.$refs.target.show();
      },
    },
    computed: {
      model: createPropModel('modelValue'),
      listeners() {
        const { click, focus, 'update:modelValue': _, ...listeners } = this.$listeners;
        return listeners;
      }
    },
  }
</script>