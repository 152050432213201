<template>
  <div class="basic-section" :class="bem('basic-section', { appearance })">
    <slot />
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'BasicSection',
    props: {
      appearance: String,
    },
    methods: {
      bem,
    }
  }
</script>