<template>
  <div
    class="rating-stars-form"
    :class="bem('rating-stars-form', { fixed, color })"
  >
    <button
      v-for="score in 5"
      :key="score"
      :disabled="disabled"
      class="rating-stars-form__star"
      :class="bem('rating-stars-form__star', { active: fixed && value === score, color })"
      @click="$emit('vote', score)"
      :title="title(score)"
    >
      <SvgIcon
        class="rating-stars-form__star-icon"
        icon="star-small"
        :size="36"
      />
    </button>
  </div>
</template>

<script>
  import { bem } from 'src/utils/bem.js';
  import { getRussianPluralDeclension } from 'src/utils/translation.js';

  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

  const titleGenerator = getRussianPluralDeclension('звезда', 'звезды', 'звезд');

  export default {
    name: 'RatingStarsForm',
    components: { SvgIcon },
    props: {
      disabled: Boolean,
      fixed: Boolean,
      value: Number,
      color: {
        type: String,
        default: 'blue',
        validator(value) {
          return ['blue', 'orange'].indexOf(value) !== -1;
        },
      },
    },
    emits: ['vote'],
    methods: {
      title(score) {
        return `${score} ${titleGenerator(score)}`;
      },
      bem,
    },
  };
</script>
