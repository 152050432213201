<template>
  <NoContent
    :appearance="appearance"
    :title="placeholder.title"
    :description="placeholder.description"
    :icon="$options.placeholderIcon"
    :actions="placeholder.actions"
  >
    <template v-if="placeholder && placeholder.typedActions" #actions>
      <template v-for="(action, index) in placeholder.actions">
        <FriendshipButton
          v-if="action.type === 'friendship'"
          v-model="action.status"
          @update:modelValue="() => { $forceUpdate(); $emit('update'); }"
          :add-button-text="action.title"
          :key="index"
          :user-id="user.id"
          appearance="primary"
          size="m"
        />
        <BasicButton
          v-else-if="action.type === 'link'"
          :href="action.href"
          :key="index"
          :appearance="action.appearance"
          size="m"
          rel="nofollow"
        >
          {{ action.title }}
        </BasicButton>
      </template>
    </template>
  </NoContent>
</template>

<script>
  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import FriendshipButton from 'src/components/buttons/FriendshipButton/FriendshipButton.vue';
  import NoContent from 'src/components/placeholders/NoContent/NoContent.vue';

  import placeholderIcon from 'images/no_content.icon.response.svg';

  export default {
    name: 'UserPagePlaceholder',
    components: { NoContent, BasicButton, FriendshipButton },
    placeholderIcon: {
      src: placeholderIcon,
      width: 96,
      height: 96,
    },
    props: {
      placeholder: {
        type: Object,
        required: true,
      },
      user: {
        type: Object,
      },
      appearance: String,
    },
    
    emits: ['update'],

    computed: {
      typedActions() {
        return this.placeholder.actions.every(x => x.type);
      }
    }
  }
</script>
