<template>
  <div
    v-if="rate && rate.amount"
    class="expert-cost"
  >
    <div class="expert-cost__rate">
      <InlineCurrency
        class="expert-cost__amount"
        :amount="rate.amount"
        :currency="rate.currency"
      />/ час
    </div>
    <div
      v-if="rate.freeIntro"
      class="expert-cost__free-intro"
    >
      Первая встреча бесплатно
    </div>
  </div>
  <div
    v-else
    class="expert-cost expert-cost--free"
  >
    Бесплатно
  </div>
</template>

<script>
  import InlineCurrency from 'src/components/InlineCurrency/InlineCurrency.vue';

  export default {
    name: 'ExpertCost',
    components: { InlineCurrency },
    props: {
      rate: {
        type: null,
        required: true,
      },
    },
  };
</script>
