<template>
  <span
    class="inline-separator"
    :class="bem('inline-separator', { color })"
  > &bull; </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'InlineSeparator',
    props: {
      color: String,
    },
    methods: {
      bem,
    }
  }
</script>