<template>
  <SectionGroup>
    <SectionBox v-if="resume.progressData">
      <BasicSection>
        <ContentSection appearance="resume">
          <template #title>
            <InlineAlign gap="small">
              <template #start>
                <BasicText size="large"
                  >Ваш профиль заполнен на {{ progressPercentage }}</BasicText
                >
              </template>
              <template #end>
                <BasicLink
                  target="_blank"
                  appearance="question"
                  href="/info/profile"
                >
                  <span class="icon icon-question" />
                </BasicLink>
              </template>
            </InlineAlign>
          </template>
          <template #aside>
            <EditButton
              v-if="resume.progressData.canHideProgress"
              title="Отключить показ"
              href="/preferences/scores"
              mobile-icon="cross"
              :icon-size="12"
            />
          </template>
          <template #default>
            <UserProfileProgress
              :progress-data="resume.progressData"
              :avatar-src="avatarSrc"
            />
          </template>
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.about">
      <BasicSection>
        <ContentSection title="Обо мне" appearance="resume">
          <template #aside>
            <EditButton v-if="resume.about.edit" :href="resume.about.edit" />
          </template>
          <UserResumePlaceholder
            v-if="resume.about.placeholder"
            :placeholder="resume.about.placeholder"
            :icon="$options.noContentAboutIcon"
          />
          <StyleUgc v-else :ugc="resume.about.value" />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox>
      <BasicSection>
        <ContentSection title="Навыки" appearance="resume">
          <InputGroup gap="large" direction="vertical">
            <SkillsListShow
              :skills="user.skills"
              is-profile
              :user-login="user.id"
            />
            <SkillBanner
              v-if="skillsBanner"
              :quiz-id="skillsBanner.quizId"
              :can-close="false"
            />
          </InputGroup>
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="showExpertScoreForm">
      <BasicSection appearance="same-paddings">
        <ContentSection appearance="resume" title="Оцените прошедшую встречу">
          <ExpertScoreForm :alias="user.id" @action="trackAction" />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.habr_expert">
      <BasicSection>
        <ContentSection appearance="resume-slim" id="expert-profile">
          <template v-if="user.isOwner" #aside>
            <EditButton href="/profile/expert/edit" />
          </template>
          <template #title>
            Эксперт<ExpertIcon class="user-resume__title-icon" />
          </template>
          <ExpertProfile
            :expert="resume.habr_expert"
            :user="user"
            :has-active-connection="hasActiveConnection"
          />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.ratings">
      <BasicSection>
        <ContentSection title="Участие в профсообществах" appearance="resume">
          <template #aside>
            <EditButton
              v-if="resume.ratings.edit"
              :href="resume.ratings.edit"
            />
          </template>
          <UserResumePlaceholder
            v-if="resume.ratings.placeholder"
            :placeholder="resume.ratings.placeholder"
            :icon="$options.noContentRatingsIcon"
          />
          <ResumeRatings
            v-else
            :ratings="resume.ratings.items"
            :is-show-contacts="resume.ratings.contactsAvailable"
            @openContacts="$emit('openContacts')"
          />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.companies">
      <BasicSection>
        <ContentSection title="Опыт работы" appearance="resume">
          <template #aside>
            <EditButton
              v-if="resume.companies.edit"
              :href="resume.companies.edit"
            />
          </template>
          <UserResumePlaceholder
            v-if="resume.companies.placeholder"
            :placeholder="resume.companies.placeholder"
            :icon="$options.noContentCompaniesIcon"
          />
          <ResumeJobExperience v-else :companies="resume.companies.items" />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.education">
      <BasicSection>
        <ContentSection title="Высшее образование" appearance="resume">
          <template #aside>
            <EditButton
              v-if="resume.education.edit"
              :href="resume.education.edit"
            />
          </template>
          <UserResumePlaceholder
            v-if="resume.education.placeholder"
            :placeholder="resume.education.placeholder"
            :icon="$options.noContentEducationIcon"
          />
          <ResumeEducations v-else :educations="resume.education.items" />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.additionalEducation">
      <BasicSection>
        <ContentSection title="Дополнительное образование" appearance="resume">
          <template #aside>
            <EditButton
              v-if="resume.additionalEducation.edit"
              :href="resume.additionalEducation.edit"
            />
          </template>
          <UserResumePlaceholder
            v-if="resume.additionalEducation.placeholder"
            :placeholder="resume.additionalEducation.placeholder"
            :icon="$options.noContentAdditionalEducationIcon"
          />
          <ResumeEducations
            v-else
            :educations="resume.additionalEducation.items"
          />
        </ContentSection>
      </BasicSection>
    </SectionBox>

    <SectionBox v-if="resume.recommendationLetters">
      <BasicSection>
        <ContentSection title="Рекомендательные письма" appearance="resume">
          <template v-if="resume.recommendationLetters.createHref" #aside>
            <BasicLink
              :href="resume.recommendationLetters.createHref"
              appearance="blue-bold"
              class="mq-not-mobile"
            >
              Написать рекомендательное письмо
            </BasicLink>
            <BasicButton
              :href="resume.recommendationLetters.createHref"
              class="mq-mobile-only"
            >
              <SvgIcon icon="plus" color="muted" size="18" />
            </BasicButton>
          </template>
          <RecommendationLetters
            :letters="resume.recommendationLetters.items"
            :approve-fallback-href="
              resume.recommendationLetters.approveFallbackHref
            "
          />
          <BasicLink
            v-if="resume.recommendationLetters.href"
            :href="resume.recommendationLetters.href"
            appearance="blue-bold"
            full-width
          >
            Посмотреть все письма ({{ resume.recommendationLetters.total }})
          </BasicLink>
        </ContentSection>
      </BasicSection>
    </SectionBox>
  </SectionGroup>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicLink from 'src/components/BasicLink/BasicLink.vue';
import EditButton from 'src/components/buttons/EditButton/EditButton.vue';
import ExpertIcon from 'src/components/experts/ExpertIcon/ExpertIcon.vue';
import ExpertProfile from 'src/components/experts/ExpertProfile/ExpertProfile.vue';
import ExpertScoreForm from 'src/components/experts/ExpertScoreForm/ExpertScoreForm.vue';
import InputGroup from 'src/components/form/InputGroup/InputGroup.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import InlineAlign from 'src/components/InlineAlign/InlineAlign.vue';
import RecommendationLetters from 'src/components/resume/RecommendationLetters/RecommednationLetters.vue';
import ResumeEducations from 'src/components/resume/ResumeEducations/ResumeEducations.vue';
import ResumeJobExperience from 'src/components/resume/ResumeJobExperience/ResumeJobExperience.vue';
import ResumeRatings from 'src/components/resume/ResumeRatings/ResumeRatings.vue';
import SectionBox from 'src/components/SectionBox/SectionBox.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
import ContentSection from 'src/components/sections/ContentSection/ContentSection.vue';
import SectionGroup from 'src/components/sections/SectionGroup/SectionGroup.vue';
import SkillBanner from 'src/components/SkillBanner/SkillBanner.vue';
import SkillsListShow from 'src/components/SkillsListShow/SkillsListShow.vue';
import StyleUgc from 'src/components/StyleUgc/StyleUgc.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import UserProfileProgress from 'src/components/users/UserProfileProgress/UserProfileProgress.vue';
import UserResumePlaceholder from 'src/components/users/UserResumePlaceholder/UserResumePlaceholder.vue';

import noContentAboutIcon from 'images/no_content.icon.about.svg';
import noContentAdditionalEducationIcon from 'images/no_content.icon.additional_education.svg';
import noContentEducationIcon from 'images/no_content.icon.education.svg';
import noContentCompaniesIcon from 'images/no_content.icon.experience.svg';
import noContentRatingsIcon from 'images/no_content.icon.ratings.svg';

export default {
  name: 'UserResume',
  components: {
    InputGroup,
    SkillsListShow,
    ExpertScoreForm,
    ExpertIcon,
    ExpertProfile,
    UserResumePlaceholder,
    RecommendationLetters,
    SvgIcon,
    BasicLink,
    ResumeEducations,
    ResumeJobExperience,
    ResumeRatings,
    StyleUgc,
    BasicButton,
    EditButton,
    ContentSection,
    BasicSection,
    SectionBox,
    SectionGroup,
    UserProfileProgress,
    BasicText,
    InlineAlign,
    SkillBanner,
  },
  noContentAboutIcon: {
    src: noContentAboutIcon,
    width: 96,
    height: 96,
  },
  noContentCompaniesIcon: {
    src: noContentCompaniesIcon,
    width: 96,
    height: 96,
  },
  noContentEducationIcon: {
    src: noContentEducationIcon,
    width: 96,
    height: 96,
  },
  noContentAdditionalEducationIcon: {
    src: noContentAdditionalEducationIcon,
    width: 96,
    height: 96,
  },
  noContentRatingsIcon: {
    src: noContentRatingsIcon,
    width: 100,
    height: 100,
  },
  props: {
    resume: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    hasActiveConnection: Boolean,
    showExpertScoreForm: Boolean,
    skillsBanner: Object,
  },
  emits: ['openContacts'],
  methods: {
    trackAction(action) {
      window.helpers.trackEvent('experts', 'review_profile', action);
    },
  },
  computed: {
    avatarSrc() {
      return this.user.avatar.image.src;
    },
    progressPercentage() {
      return `${this.resume.progressData.progress}%`;
    },
  },
};
</script>
